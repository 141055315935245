import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
// import en from '../../public/assets/locales/en/en'

// const LANGUAGES = [
//   'fr',
//   'tur',
//   'ch',
//   'kor',
//   'en',
//   'pl',
//   'de',
//   'es',
//   'rus',
//   'jap',
//   'por'
// ]
const DEFAULT_LANGUAGE = 'en'

i18next
  .use(initReactI18next)
  .use(Backend)
  .init({
    supportedLngs: ['ko', 'en'],
    fallbackLng: DEFAULT_LANGUAGE,
    backend: {
      loadPath: `/assets/locales/{{lng}}/translation.json`
    },
    react: {
      useSuspense: false
    }
  })

export default i18next
