import React from 'react'
import { ReactComponent as BuffCoin } from '../../../../svg/claim/moneta.svg'

interface ICircle {
  pct: number
  strokeWidth: number
  color: string
}

const Circle: React.FC<ICircle> = (props: any) => {
  const r = 131
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - props.pct!) * circ) / 100
  return (
    <circle
      r={r}
      cx={66}
      cy={135}
      fill="transparent"
      stroke={strokePct !== circ ? props.color : ''} // remove colour as 0% sets full circumference
      strokeWidth={props.strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={props.pct ? strokePct : 0}
      strokeLinecap="round"
    />
  )
}

interface IPie {
  width: number
  height: number
  mainStrokeWidth: number
  secondaryStrokeWidth: number
  percentageClaimed: number
  percentageLeft: number
  style?: React.CSSProperties
}

export const Pie: React.FC<IPie> = (props) => {
  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <svg
        style={{
          width: `${props.width}`,
          height: `${props.height}`,
          alignItems: 'center',
          justifyContent: 'center',
          ...props.style
        }}
      >
        <g transform={`rotate(-90 ${'100 101'})`}>
          <Circle
            pct={100}
            color="var(--secondary)"
            strokeWidth={props.mainStrokeWidth}
          />
          {props.percentageClaimed !== 0 && (
            <Circle
              color={'var(--secondary)'}
              strokeWidth={props.secondaryStrokeWidth}
              pct={props.percentageClaimed}
            />
          )}

          <g
            style={{ transform: 'translate(12px, 27px) scale(0.8)', zIndex: 4 }}
          >
            <Circle
              pct={100}
              color="var(--primary)"
              strokeWidth={props.mainStrokeWidth}
            />
            {props.percentageLeft !== 0 && (
              <Circle
                color={'var(--primary)'}
                strokeWidth={props.secondaryStrokeWidth}
                pct={props.percentageLeft}
              />
            )}

            <g style={{ transform: 'translate(-45px, 6.5px) scale(1.2)' }}>
              <g transform={`rotate(90 ${'100 100'})`}>
                <g
                  style={{
                    transform: `scale(1.3)`
                  }}
                >
                  <BuffCoin style={{}} />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}
