import { MetaMaskProvider, useMetaMask } from 'metamask-react'
import React from 'react'
import { EthNetwork, EthNetworkConfig, EthServices, EthState } from './EthTypes'
import { initialUpdate, useEthUpdate } from './EthUpdate'
import { EthReadService } from './services/EthReadService'
import { EthWriteService } from './services/EthWriteService'
import { EthUtilsService } from './services/EthUtilsService'
import { config as ethConfig } from './EthConfig'
import { getSaleProgress } from './getSaleProgress'
import { getUserClaimRatio } from './getClaimRatio'

export interface EthProviderProps {
  initialConfig: EthNetwork
}

export const EthContext = React.createContext<EthState>(null as any)

export const createServicesInstance = (
  config: EthNetworkConfig
): EthServices => ({
  read: new EthReadService(config),
  write: new EthWriteService(config),
  utils: new EthUtilsService(config)
})

export const EthProviderState: React.FC<EthProviderProps> = (props) => {
  const [config, setConfig] = React.useState(ethConfig[props.initialConfig])

  // const [ethUpdate, setEthUpdate] = React.useState(initialUpdate())
  const [ethUpdate] = React.useState(initialUpdate())
  const [bscUpdate, setBscUpdate] = React.useState(initialUpdate())

  const ethServices = createServicesInstance(ethConfig.eth)
  const bscServices = createServicesInstance(ethConfig.bsc)

  const services = config.network === 'eth' ? ethServices : bscServices
  const update = config.network === 'eth' ? ethUpdate : bscUpdate

  const validationError = services.utils.validateProvider()
  const metamask = useMetaMask()

  const switchConfig = (network: EthNetwork) => {
    setConfig(ethConfig[network])
  }

  // useEthUpdate(ethConfig.eth, setEthUpdate, ethServices, metamask.status)
  useEthUpdate(ethConfig.bsc, setBscUpdate, bscServices, metamask.status)

  const state: EthState = {
    metamask,
    config: config,
    services,
    error: validationError,
    update,
    switchConfig,
    saleProgress: getSaleProgress(ethUpdate, bscUpdate),
    claimRatio: getUserClaimRatio(update)
  }

  return (
    <EthContext.Provider value={state}>{props.children}</EthContext.Provider>
  )
}

export const EthereumProvider: React.FC<EthProviderProps> = (props) => {
  return (
    <MetaMaskProvider>
      <EthProviderState {...props}>{props.children}</EthProviderState>
    </MetaMaskProvider>
  )
}

export const useEth = () => React.useContext(EthContext)
