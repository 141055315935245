import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { ReactComponent as ETH } from '../../icons/ETH.svg'
import { ReactComponent as BSC } from '../../icons/BSC.svg'
import { ReactComponent as ETHInactive } from '../../icons/ETHInactive.svg'
import { ReactComponent as BSCInactive } from '../../icons/BSCInactive.svg'
import { BuyTokenInterface, TabNames } from '../../utils/enums'
import ActiveInactiveTab from '../ActiveInactiveTab'
import { handleTabs } from '../../BuyToken'
import { useEth } from '../../../../Logic/Eth/EthProvider'
import styles from './BalanceDashboard.module.css'
// import BalanceDashboardCard from './BalanceDashboardCard'
import { Hr } from '../../../LandingPage/sections/BettingPlatform'
import Text from '../../../../Components/Text'
// import { ProgressBarVestingValue } from './ProgressBarClaim/ProgressBarVestingValue'
import CounterTimeLeft from './Counter/CounterTimeLeft'
import { ProgressBarDate } from './ProgressBarClaim/ProgressBarDate'
import { ProgressDateBar } from './ProgressDateBar'
import {
  claimTimestamp4,
  claimTimestamp5,
  claimTimestamp6
} from './ProgressBarClaim/Dates'
import { Pie } from './Circle'
import useWindowDimensions from '../../../../Logic/Hook/useWindowDimensions'
// import { useEthTransaction } from '../../../../Logic/Eth/EthTransaction'
import './BalanceDashboard.css'
import ClaimDev from '../../../Dev/sections/ClaimDev'

export interface BalanceDashboardProps {
  localState: BuyTokenInterface
  setLocalState: React.Dispatch<React.SetStateAction<BuyTokenInterface>>
  style?: React.CSSProperties
}

const BalanceDashboard: React.FC<BalanceDashboardProps> = ({
  localState,
  setLocalState,
  style
}) => {
  // const tx = useEthTransaction()
  const { width } = useWindowDimensions()

  const eth = useEth()

  return (
    <section id={styles.claimToken} className="claim-token">
      <div className={styles.container}>
        <div className={styles.tabsContainer}>
          <Tabs
            id="controlled-tab-example"
            activeKey={localState.activeKey}
            onSelect={(tabName: string | null) =>
              handleTabs(tabName, eth, localState, setLocalState)
            }
            className={styles.claimTabs}
          >
            <Tab
              eventKey={TabNames.BSC}
              title={
                <ActiveInactiveTab
                  name={TabNames.BSC}
                  selected={localState.activeKey}
                  active={<BSC className={styles.svgTab} />}
                  inactive={<BSCInactive className={styles.svgTab} />}
                />
              }
              tabClassName={styles.claimTab}
            />

            <Tab
              eventKey={TabNames.ETH}
              disabled
              title={
                <ActiveInactiveTab
                  name={TabNames.ETH}
                  selected={localState.activeKey}
                  active={<ETH className={styles.svgTab} />}
                  inactive={<ETHInactive className={styles.svgTab} />}
                />
              }
              className={styles.claimTab}
            />
          </Tabs>

          <div className={styles.boxClaim}>
            <ClaimDev />

            <Hr
              style={{
                flexDirection: width >= 1120 ? 'column' : 'row',
                width: width >= 1120 ? 1 : '100%',
                margin: '10px 15px',
                height: width >= 1120 ? 'auto' : '2px',
                alignItems: 'center',
                backgroundColor: "transparent"
              }}
            />

            <div className={styles.secondDiv}>
              <div
                style={{
                  width: width > 400 ? '60%' : '53%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Text
                  style={{
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: 700,
                    color: '#1D1C39'
                  }}
                >
                  Vesting
                </Text>
                <Text
                  style={{ marginBottom: 10, fontSize: 10, fontWeight: 400 }}
                >
                  BUFFS Token Investment
                </Text>

                <div
                  style={{
                    marginTop: 35,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <div style={{ transform: width > 400 ? '' : 'scale(0.85)' }}>
                    <Pie
                      width={269}
                      height={269}
                      mainStrokeWidth={3}
                      secondaryStrokeWidth={8}
                      percentageClaimed={eth.claimRatio.claimed}
                      percentageLeft={eth.claimRatio.left}
                    />
                  </div>

                  <div>
                    <CounterTimeLeft />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  height: '100%',
                  width: '30%',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Text
                  style={{
                    color: '#1D1C39',
                    margin: 0,
                    position: 'absolute',
                    right: -12,
                    alignItems: 'center',
                    fontSize: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 700,
                    width: '100%'
                  }}
                >
                  Start
                </Text>
                <div style={{ marginTop: 20 }}>
                  <ProgressBarDate />
                  <ProgressDateBar date={claimTimestamp5} hasBorderBottom />
                  <ProgressDateBar date={claimTimestamp4} hasBorderBottom />
                  <ProgressDateBar
                    date={claimTimestamp6}
                    hasBorderBottom={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BalanceDashboard
