import { EthUpdate } from './EthTypes'
import { config } from './EthConfig'

export const getSaleProgress = (ethUpdate: EthUpdate, bscUpdate: EthUpdate) => {
  try {
    // const ethLocked = getLockedValue(ethUpdate.totalLocked)
    const ethLocked = 37711.7526
    const bscLocked = getLockedValue(bscUpdate.totalLocked)

    const locked = ethLocked + bscLocked
    const goal = Number(config.saleGoal) / 10 ** config.tokenDecimals

    const num = Math.round((locked / goal) * 100)

    return config.minSaleProgress > num ? config.minSaleProgress : num
  } catch (e) {
    console.error(e)
    return config.minSaleProgress
  }
}

const getLockedValue = (value: bigint | null) => {
  if (value === null) {
    return 0
  }
  return Number(value) / 10 ** config.tokenDecimals
}
