import styled from 'styled-components'

export interface LinkButtonProps {
  text: string
  variant: 'primary' | 'secondary'
  arrow: boolean
  onClick?: (e?: any) => any
  link?: string
  style: React.CSSProperties
  disabled?: boolean
  GAEvent?: string
}

const LINK_BUTTON_DEFAULT_PROPS: LinkButtonProps = Object.freeze({
  text: '',
  variant: 'secondary',
  arrow: false,
  style: {}
})

const CustomLinkButton = styled.button<{ variant: 'primary' | 'secondary' }>`
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  border-radius: 1000px;
  color: white;
  box-shadow: 4.82956px 5.06708px 9px rgba(0, 0, 0, 0.15);
  border: 0;
  transition: filter 0.15s linear;
  filter: brightness(1);
  background-color: ${({ variant }) => `var(--${variant})`};

  &:hover {
    filter: ${({ variant }) =>
      `brightness(${variant === 'primary' ? '1.05' : '1.15'})`};
  }

  &:disabled {
    background-color: #aaa;
    cursor: unset;
    filter: none;
  }
`

const LinkButton: React.FC<Partial<LinkButtonProps>> = (_props) => {
  const props = { ...LINK_BUTTON_DEFAULT_PROPS, ..._props }

  return (
    <a
      href={props.link}
      style={{ textDecoration: 'none', maxWidth: '100%', display: 'flex' }}
    >
      <CustomLinkButton
        variant={props.variant}
        style={props.style}
        disabled={props.disabled}
      >
        {props.text}
        {props.arrow && (
          <img
            style={{ marginLeft: 10 }}
            src="/assets/images/common/arrow-right.svg"
            alt="arrow-right"
          />
        )}
      </CustomLinkButton>
    </a>
  )
}

export default LinkButton
