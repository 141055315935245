import styled from 'styled-components'
import { useEth } from '../../../../Logic/Eth/EthProvider'
import { EthTransactionState } from '../../../../Logic/Eth/EthTransaction'
import CopyButton from './CopyButton'

const shortId = (id: string, n: number) => {
  return `${id.substring(0, n + 2)}...${id.substring(id.length - n, id.length)}`
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .tx-id-name {
    font-weight: normal;
    font-size: 12px;
    color: var(--headline);
    margin-bottom: 10px;
  }

  & .tx-id {
    margin: 5px 0;
  }

  & .tx-id-text {
    font-size: 16px;
    border: solid var(--secondary) 1px;
    border-radius: 26px;
    padding: 6px 24px;
    color: var(--headline);
    display: inline-block;
  }

  & .tx-link {
    font-size: 12px;
    color: var(--headline);
    opacity: 0.8;
    cursor: pointer;
    margin-top: 8px;
    transition: transform 0.2s linear;

    &:hover {
      transform: translateX(4px);
    }
  }
`

const SuccessContent: React.FC<EthTransactionState> = (props) => {
  const eth = useEth()

  if (props.status !== 'success') return null

  const gotoExplorer = () => {
    const lastHash = props.hashes[props.hashes.length - 1]
    const url = `${eth.config.explorerUrl}/tx/${lastHash}`

    window.open(url, '_blank')
  }

  return (
    <Styled>
      {props.hashes.map((hash) => (
        <div className="tx-id" key={hash}>
          <div className="tx-id-text">{shortId(hash, 4)}</div>
          <CopyButton text={hash} style={{ marginLeft: 10 }} />
        </div>
      ))}
      <div className="tx-link" onClick={gotoExplorer}>
        go to bscscan &rarr;
      </div>
    </Styled>
  )
}

export default SuccessContent
