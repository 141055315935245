import styled from 'styled-components'

export interface CloseButtonProps {
  onClick: () => void
  disabled: boolean
}

const Styled = styled.div<CloseButtonProps>`
  font-size: 20px;
  font-weight: 700;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  transition: all 0.1s linear;
  margin-left: auto;
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(p) => (p.disabled ? '#ccc' : '#666')};

  &:hover {
    transform: ${(p) => (!p.disabled ? 'rotate(90deg)' : 'none')};
    background-color: ${(p) => (!p.disabled ? '#e8e8e8' : 'none')};
  }
`

const CloseButton: React.FC<CloseButtonProps> = (props) => {
  return (
    <Styled
      disabled={props.disabled}
      onClick={!props.disabled ? props.onClick : () => {}}
    >
      &#x2715;
    </Styled>
  )
}

export default CloseButton
