import styled from 'styled-components'

import Text from '../../../../../Components/Text'

export const StyledContainer = styled.div`
  margin-top: 45px;
  width: 100%;
`

export const StyledProgressBar = styled.div`
  width: 100%;
  background-color: #777;
  height: 3px;
  position: relative;
`

export const ProgressBarFill = styled.div<{ percentage: number }>`
  position: absolute;
  background: var(--primary);
  left: 0;
  top: 0;
  bottom: 0;
  right: ${(p) => 100 - p.percentage}%;
  height: 3px;
`

export const StyledStats = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`

export const StyledLabel = styled(Text)`
  font-size: 14px;
  font-weight: 200;
`

export const StyledValue = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  margin-left: 5px;
`
