import styled from 'styled-components'

export interface VideoPlayerProps {
  id: string
  style?: React.CSSProperties
}

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 600px;
`

const VideoWrapper = styled.div`
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  margin: auto;
  max-width: 1200px;
  max-height: 600px;
`

const Black = styled.div`
  background-color: black;
  max-height: calc(604px);
  padding: 2px;
`

const ImgTop = styled.img`
  width: 100%;
  transform: translateY(2px);
  height: 35px;
  object-fit: cover;
`

const ImgBottom = styled.img`
  width: 100%;
  transform: translateY(-1px);
  height: 35px;
  object-fit: cover;
`

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  return (
    <div style={props.style}>
      <ImgTop src="/assets/images/common/video-top.svg" alt="video" />
      <Black>
        <VideoWrapper>
          <Iframe
            src={`https://www.youtube.com/embed/${props.id}?color=white`}
          />
        </VideoWrapper>
      </Black>
      <ImgBottom src="/assets/images/common/video-bottom.svg" alt="video" />
    </div>
  )
}

export default VideoPlayer
