import styled from 'styled-components'
import React from 'react'

export interface ViewControllerOptionProps {
  text: string
  active: boolean
  onClick: () => void
}

const Styled = styled.div<Pick<ViewControllerOptionProps, 'active'>>`
  font-weight: bold;
  font-size: 16px;
  color: ${(p) => (p.active ? '#28293D' : '#8E90A6')};
  background-color: ${(p) => (p.active ? '#F2F2F5' : 'transparent')};
  border-radius: 16px;
  padding: 8px 36px;
  cursor: ${(p) => (p.active ? 'default' : 'pointer')};
  transition: all 0.1s linear;
`

const ViewControllerOption: React.FC<ViewControllerOptionProps> = (props) => {
  return (
    <Styled
      active={props.active}
      onClick={props.active ? undefined : props.onClick}
    >
      {props.text}
    </Styled>
  )
}

export default ViewControllerOption
