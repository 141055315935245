import { ClaimPeriodType } from '../Views/BuyToken/components/BalanceDashboard/ProgressBarClaim/Dates'

const timeToProgress = (start: number, stop: number, actual: number) => {
  const val = ((actual - start) * 100) / (stop - start)
  return Math.min(Math.max(val, 0), 100)
}

/**
 *
 * @param percents - normal percents
 * @param height - height of progress bar
 * @param paddingSize - size of padding
 * @param date - actual date to chose right paddings and their parts
 * @param paddings  - paddings by date start/stop
 * @returns
 */
const fixupProgressForPaddings = (
  percents: number,
  height: number,
  paddingSize: number,
  date: number,
  paddings: { start: number; stop: number }[]
) => {
  // calculate height substracting paddings
  let heightPerPercent = (height - paddings.length * paddingSize) / 100
  let resultingHeight = percents * heightPerPercent

  // now add those additional padding pixels conditionally
  for (let i = 0; i < paddings.length; i++) {
    const padding = paddings[i]
    if (padding.stop < date) {
      // add full padding
      resultingHeight += paddingSize
    } else if (padding.start <= date) {
      // add part of padding
      const val =
        (paddingSize * (date - padding.start)) / (padding.stop - padding.start)
      resultingHeight += val
    } else {
      break // if this padding has not started - skip rest
    }
  }

  // return as percents
  return (resultingHeight * 100) / height
}

const createPaddings = (periods: ClaimPeriodType[]) => {
  const count = periods.length
  const paddings: { start: number; stop: number }[] = []
  for (let i = 0; i < count - 1; i++) {
    paddings.push({
      start: periods[i].timestamps.slice(-1)[0],
      stop: periods[i + 1].timestamps[0]
    })
  }
  return paddings
}

export { timeToProgress, fixupProgressForPaddings, createPaddings }
