import React from 'react'

export const numberToDigits = (num: number): [number, number] => {
  const x = Math.floor(isNaN(num) ? 100 : num)

  if (x < 0) return [0, 0]
  if (x > 99) return [9, 9]
  if (x < 10) return [0, x]

  return [Math.floor(x / 10), x % 10]
}

// Transform array like [1,1,1,1,1,1,1,1] into [[1,1], [1,1], [1,1], [1,1]]
export const groupDigits = (arr: number[]): number[][] => {
  if (arr.length % 2 !== 0) return []

  const pairs: number[][] = []

  for (let i = 0; i < arr.length; i += 2) {
    const pair = [arr[i], arr[i + 1]]
    pairs[Math.floor(i / 2)] = pair
  }

  return pairs
}

export const getDigits = (
  currentTimeProvider: () => number,
  goal: number
): number[] => {
  const arr = Array(8).fill(0)
  let diff = goal - currentTimeProvider()

  if (diff < 0) return arr

  const sec = 1000
  const min = 60 * sec
  const hour = 60 * min
  const day = 24 * hour

  const daysLeft = Math.floor(diff / day)
  diff %= day

  const hoursLeft = Math.floor(diff / hour)
  diff %= hour

  const minsLeft = Math.floor(diff / min)
  diff %= min

  const secLeft = Math.floor(diff / sec)
  diff %= sec

  if (daysLeft > 99) return [9, 9, 2, 3, 5, 9, 5, 9]

  return [
    ...numberToDigits(daysLeft),
    ...numberToDigits(hoursLeft),
    ...numberToDigits(minsLeft),
    ...numberToDigits(secLeft)
  ]
}

export const makeDigits = (goal: number) =>
  groupDigits(getDigits(Date.now, goal))

export const useCounter = (goal: number) => {
  const [digits, setDigits] = React.useState(makeDigits(goal))

  React.useEffect(() => {
    const handle = setInterval(() => {
      setDigits(makeDigits(goal))
    }, 1000)

    return () => clearInterval(handle)
  }, [goal])

  return digits
}
