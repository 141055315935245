import React, { useEffect } from 'react'
import { Dropdown, Tab, Tabs } from 'react-bootstrap'
// import { presets } from '../../Logic/Eth/EthConfig'
import { useEth } from '../../Logic/Eth/EthProvider'
import { useEthTransaction } from '../../Logic/Eth/EthTransaction'
import WalletDetails from './components/WalletDetails'
import { BuyTokenInterface, Options, TabNames, Tokens } from './utils/enums'
import { calculateValue, trimInput, tokenPrecision } from './utils/utils'
import TransactionModal from './components/TransactionModal/TransactionModal'
import { ReactComponent as ETH } from './icons/ETH.svg'
import { ReactComponent as BSC } from './icons/BSC.svg'
import { ReactComponent as ETHInactive } from './icons/ETHInactive.svg'
import { ReactComponent as BSCInactive } from './icons/BSCInactive.svg'
import './buy-token.css'
import TransactionProcess from './components/TransactionProcess'
import ActiveInactiveTab from './components/ActiveInactiveTab'
import Text from '../../Components/Text'
import useWindowDimensions from '../../Logic/Hook/useWindowDimensions'
import { ReactComponent as Arrow } from '../../svg/BuyToken/arrow.svg'
import { Hr } from '../LandingPage/sections/BettingPlatform'
import config from '../../config'
import Button from '../../Components/Button'
import { ViewType } from './components/ViewController/ViewController'
import { RightOutlined } from '@ant-design/icons'
import QRCode from 'react-qr-code'
import BuyTokenStats from './components/BuyTokenStats/BuyTokenStats'
import InformationList from './components/InformationList/InformationList'
import { EthState } from '../../Logic/Eth/EthTypes'
import { config as ethConfig } from '../../Logic/Eth/EthConfig'

interface BuyTokenProps {
  setView: React.Dispatch<ViewType>
  localState: BuyTokenInterface
  setLocalState: React.Dispatch<React.SetStateAction<BuyTokenInterface>>
}
export const handleTabs = (
  tabName: string | null,
  eth: EthState,
  localState: BuyTokenInterface,
  setLocalState: React.Dispatch<React.SetStateAction<BuyTokenInterface>>
) => {
  const parsedTabName = tabName === 'ETH' ? TabNames.ETH : TabNames.BSC

  if (tabName === TabNames.BSC) {
    eth.switchConfig('bsc')
    setLocalState({
      ...localState,
      activeKey: parsedTabName,
      dropdownName: Options.BNB,
      dropdownFirstOption: Options.BNB,
      dropdownSecondOption: Options.BUSD,
      token: Tokens.Native
    })
  } else if (tabName === TabNames.ETH) {
    eth.switchConfig('eth')
    setLocalState({
      ...localState,
      activeKey: parsedTabName,
      dropdownName: Options.ETH,
      dropdownFirstOption: Options.ETH,
      dropdownSecondOption: Options.USDT,
      token: Tokens.Native
    })
  }
}
const BuyToken: React.FC<BuyTokenProps> = ({
  setView,
  localState,
  setLocalState
}) => {
  const [tokenInputValue, setTokenInputValue] = React.useState('')
  const [buffSwapInputValue, setBuffSwapInputValue] = React.useState('')
  const [inputTouched, setInputTouched] = React.useState<boolean>(false)
  const { width } = useWindowDimensions()
  const eth = useEth()
  const tx = useEthTransaction()
  // eslint-disable-next-line
  const [stablecoinProcessAmount, setStablecoinProcessAmount] = React.useState<
    bigint | null
  >(null)
  const [anotherModalToggle, setAnotherModalToggle] =
    React.useState<boolean>(false)
  // const getWarningTab = React.useCallback(() => {
  //   const currentChainID: string = eth.metamask.chainId!
  //   const rinkebyChainID: string = presets.rinkebyBuy.chainId

  //   return currentChainID === rinkebyChainID ? TabNames.BSC : TabNames.ETH
  // }, [eth.metamask.chainId])

  const decimalPrecision = React.useCallback(
    (input: Options) => {
      switch (input) {
        case Options.BNB:
          return 18

        case Options.USDT:
          return eth.config.usdDecimals

        case Options.BUSD:
          return eth.config.usdDecimals

        case Options.BUFFS:
          return ethConfig.tokenDecimals

        default:
          return 18
      }
    },
    [eth]
  )
  const isInputDisabled = React.useCallback(() => {
    return !!eth.error
  }, [eth.error])

  const clearInput = () => {
    setTokenInputValue('')
    setBuffSwapInputValue('')
    setInputTouched(false)
  }

  const validateMinMax = React.useCallback(
    (value: bigint, min: bigint, max: bigint) => {
      if (value < min) {
        setLocalState((l) => ({
          ...l,
          secondInputError: `Minimum transfer value is 200$`
        }))
      } else if (value > max) {
        setLocalState((l) => ({
          ...l,
          secondInputError: `Maximum transfer value is 15000$`
        }))
      } else {
        setLocalState((l) => ({ ...l, secondInputError: '' }))
      }
      return value
    }, // eslint-disable-next-line
    []
  )

  const handleTokenInputChanged = React.useCallback((input: string) => {
    setInputTouched(true)
    setTokenInputValue(input)
  }, [])

  const getStableCoin = (token: Options) => {
    switch (token) {
      case Options.BNB:
        return Options.BUSD
      default:
        return Options.USDT
    }
  }

  const calculateStableCoinValue = React.useCallback(
    (value: bigint, valueToken: Options, token: Tokens) => {
      if (token === Tokens.StableCoin) return value

      const price = eth.update.ethPrice

      if (!price) return 0n

      return (price * value) / BigInt(tokenPrecision(valueToken) * 100)
    },
    [eth]
  )

  // const validateSecondInput = (input: string) => {
  //   return maxInputLength(input, Options.BUFFS, true)
  // }

  const validateInput = React.useCallback(
    (value: string): any => {
      const MAX_COIN_VALUE = 1_000_000_000_000
      const num = Number(value)
      const precision = decimalPrecision(localState.dropdownName)
      let finalValue: string = value
      let error: string = ''

      if (value === '') {
        error = 'Amount is not a valid number'
      }
      if (!Number.isFinite(num)) {
        error = 'Amount is not a valid number'
      }
      if (num < 0) {
        error = 'Amount is negative'
      }
      if (num === 0) {
        error = 'Amount is 0'
      }
      if (num > MAX_COIN_VALUE) {
        error = 'Amount is too high'
      }
      if (value.split('.')[1]?.length > precision) {
        error = `Maximal precission is ${precision}`
        finalValue = trimInput(value)
        return { finalValue, error }
      }

      return { finalValue, error }
    },
    [decimalPrecision, localState]
  )

  const isBalanceCorrect = React.useCallback((): boolean | undefined => {
    const calculatedValue = calculateValue(
      localState.dropdownName,
      Number(tokenInputValue)
    )

    let balance = null

    switch (localState.token) {
      case 'native':
        balance = eth.update.userNativeBalance
        break
      case 'stable_coin':
        balance = eth.update.userUSDBalance
        break
    }

    if (!balance) {
      return false
    }

    if (calculatedValue > balance) {
      return false
    } else if (calculatedValue <= balance) {
      return true
    }

    return false
  }, [
    localState,
    tokenInputValue,
    eth.update.userNativeBalance,
    eth.update.userUSDBalance
  ])

  const validateBuffTokens = React.useCallback(() => {
    if (buffSwapInputValue === '') return
    const buffTokenAmount = calculateValue(
      Options.BUFFS,
      Number(buffSwapInputValue)
    )
    const selectedTokenValue = eth.services.utils.calculatePaymentAmount(
      buffTokenAmount,
      localState.token,
      eth.update.ethPrice!
    ) // Token value in ETH / USDT / BNB / BUSD

    // Stable coin value validation
    const stableCoinValue = calculateStableCoinValue(
      selectedTokenValue,
      localState.dropdownName,
      localState.token
    )
    const stableCoin = getStableCoin(localState.dropdownName)
    // Value of tokens in stable coins must be in range of 200-15000 USD
    validateMinMax(
      stableCoinValue,
      BigInt(200 * tokenPrecision(stableCoin) - 1),
      BigInt(15000 * tokenPrecision(stableCoin) + 1)
    )
  }, [
    buffSwapInputValue,
    calculateStableCoinValue,
    eth,
    localState,
    validateMinMax
  ])

  const validateBalances = React.useCallback(() => {
    if (!isBalanceCorrect()) {
      setLocalState((l) => ({
        ...l,
        firstInputError: 'Your balance is too low'
      }))
    }
    validateBuffTokens() // eslint-disable-next-line
  }, [isBalanceCorrect, validateBuffTokens])

  const isSwapDisabled = React.useCallback((): boolean => {
    if (stablecoinProcessAmount) return true

    return !!(
      eth.error ||
      localState.firstInputError ||
      !inputTouched ||
      !isBalanceCorrect()
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eth.error,
    localState.firstInputError,
    inputTouched,
    isBalanceCorrect,
    localState.secondInputError,
    stablecoinProcessAmount
  ])

  const handleSwap = async () => {
    const bigintValue = calculateValue(
      localState.dropdownName,
      Number(tokenInputValue)
    )

    if (localState.token === Tokens.Native) {
      await tx.init(
        bigintValue,
        'buyForNativeCoin',
        eth.services.utils.getCrowdsaleAddress()
      )
    }

    if (localState.token === Tokens.StableCoin) {
      setStablecoinProcessAmount(bigintValue)
    }
  }

  const calculatedTokenValue = React.useCallback(() => {
    if (!eth.update.ethPrice) return ''

    const bn = calculateValue(localState.dropdownName, Number(tokenInputValue))
    const result = eth.services.utils.calculateTokenAmount(
      bn,
      localState.token,
      eth.update.ethPrice
    )
    if (result === BigInt(0)) {
      return ''
    }

    return (Number(result) / 10 ** 4).toFixed(4)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tokenInputValue,
    eth.update.ethPrice,
    localState.dropdownName,
    buffSwapInputValue
  ])
  React.useEffect(() => {
    if (eth.config.network === 'eth') {
      setLocalState({ ...localState, activeKey: TabNames.ETH })
    }

    if (eth.config.network === 'bsc') {
      setLocalState({ ...localState, activeKey: TabNames.BSC })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eth.config])

  useEffect(() => {
    if (!inputTouched) return
    const { error } = validateInput(tokenInputValue)

    setLocalState((ls) => ({ ...ls, firstInputError: !!error ? error : '' }))

    const buffSwapTokenValue = calculatedTokenValue()
    setBuffSwapInputValue(buffSwapTokenValue)
    validateBalances()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInputValue, calculatedTokenValue])

  return (
    <>
      <section id="buy-token">
        <div
          style={{
            display: width >= 930 ? 'flex' : 'block',
            maxWidth: '100%',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              minWidth: width >= 930 ? '444px' : '330px',
              height: 'max-content',
              width: '100%',
              position: 'relative',
              marginBottom: 25
            }}
          >
            <Tabs
              id="controlled-tab-example"
              activeKey={localState.activeKey}
              onSelect={(tabName: string | null) =>
                handleTabs(tabName, eth, localState, setLocalState)
              }
              style={{ position: 'relative', minWidth: '330px' }}
            >
              <Tab
                eventKey={TabNames.BSC}
                title={
                  <ActiveInactiveTab
                    name={TabNames.BSC}
                    selected={localState.activeKey}
                    active={
                      <BSC
                        style={{
                          width: '25px',
                          height: '25px',
                          marginLeft: '10px'
                        }}
                      />
                    }
                    inactive={
                      <BSCInactive
                        style={{
                          width: '25px',
                          height: '25px',
                          marginLeft: '10px'
                        }}
                      />
                    }
                  />
                }
                className="tab"
              ></Tab>

              <Tab
                eventKey={TabNames.ETH}
                disabled
                title={
                  <ActiveInactiveTab
                    name={TabNames.ETH}
                    selected={localState.activeKey}
                    active={
                      <ETH
                        style={{
                          width: '25px',
                          height: '25px',
                          marginLeft: '10px',
                          filter: 'grayscale(100%) brightness(1.5)'
                        }}
                      />
                    }
                    inactive={
                      <ETHInactive
                        style={{
                          width: '25px',
                          height: '25px',
                          marginLeft: '10px',
                          filter: 'grayscale(100%) brightness(1.5)'
                        }}
                      />
                    }
                  />
                }
                className="tab"
              ></Tab>
            </Tabs>

            <div className="box">
              <div className="inner-box">
                {stablecoinProcessAmount === null ? (
                  <WalletDetails localState={localState} />
                ) : (
                  <TransactionProcess
                    inputValue={stablecoinProcessAmount}
                    inputPrecision={BigInt(
                      decimalPrecision(localState.dropdownName)
                    )}
                    inputToken={localState.dropdownName}
                    handleGrantAccessRejected={() => {
                      setStablecoinProcessAmount(null)
                    }}
                  />
                )}
                <div className="input-holder">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      position: 'relative'
                    }}
                  >
                    <input
                      placeholder="0.0000"
                      type="number"
                      disabled={isInputDisabled()}
                      value={tokenInputValue}
                      style={{ width: '100%' }}
                      onChange={(e) => handleTokenInputChanged(e.target.value)}
                    />
                    <Dropdown
                      style={{
                        position: 'absolute',
                        right: '12px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1
                      }}
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        style={{
                          height: '40px',
                          backgroundColor: 'var(--secondary)',
                          border: 'none',
                          padding: '6px 16px',
                          borderRadius: '20px'
                        }}
                      >
                        {localState.dropdownName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{}}>
                        <Dropdown.Item
                          style={{ position: 'relative' }}
                          onClick={() => {
                            clearInput()
                            setLocalState({
                              ...localState,
                              dropdownName: localState.dropdownFirstOption,
                              token: Tokens.Native,
                              firstInputError: '',
                              secondInputError: ''
                            })
                          }}
                        >
                          {localState.dropdownFirstOption}
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ position: 'relative' }}
                          onClick={() => {
                            clearInput()
                            setLocalState({
                              ...localState,
                              dropdownName: localState.dropdownSecondOption,
                              token: Tokens.StableCoin,
                              firstInputError: '',
                              secondInputError: ''
                            })
                          }}
                        >
                          {localState.dropdownSecondOption}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="input-help-text" style={{ padding: 0 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'center',
                        paddingTop: 10,
                        paddingBottom: 10,
                        position: 'relative'
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          left: 30
                        }}
                      >
                        {/* <MaxButton
                          token={localState.token}
                          currentFieldValue={tokenInputValue}
                          setFieldValue={(value) => {
                            setInputTouched(true)
                            setTokenInputValue(value)
                          }}
                        /> */}
                      </div>
                      <Arrow style={{}} />
                      {localState.firstInputError && (
                        <span
                          style={{
                            color: 'red',
                            minHeight: '30px',
                            textAlign: 'center',
                            fontSize: 12,
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            right: 15
                          }}
                        >
                          {localState.firstInputError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center'
                    }}
                  >
                    <input
                      placeholder="0.0000"
                      type="number"
                      value={buffSwapInputValue}
                      onChange={(e) =>
                        setBuffSwapInputValue(e.currentTarget.value)
                      }
                      disabled={true}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        right: '46px',
                        height: '40px',
                        cursor: 'default',
                        backgroundColor: 'var(--primary)',
                        border: 'none',
                        padding: '6px 16px',
                        borderRadius: '20px',
                        color: '#fff',
                        fontWeight: 700,
                        fontSize: 16,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      BUFFS
                    </div>
                  </div>
                  <div
                    // className="input-help-text"
                    style={{
                      padding: '5px 0',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        fontWeight: 500,
                        lineHeight: '15px',
                        color: '#BBBCCA'
                      }}
                    >
                      YOU WILL GET
                    </Text>
                  </div>
                </div>
                <div className="button-holder">
                  <button
                    className={
                      isSwapDisabled() ? 'execute disabled' : 'execute'
                    }
                    disabled={isSwapDisabled()}
                    onClick={() => handleSwap()}
                  >
                    BUY
                    {/* {(tx.status === 'mining' || tx.status === 'sending') && (
                  <div className="lds-dual-ring"></div>
                )} */}
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  paddingTop: 10,
                  cursor: 'pointer'
                }}
                onClick={() => setAnotherModalToggle(!anotherModalToggle)}
              >
                <Text style={{ textAlign: 'center' }}>Another way to buy</Text>
                <Arrow style={{ display: 'block', alignItems: 'center' }} />
              </div>
              {anotherModalToggle && (
                <div className="dropdown-animation">
                  <Hr style={{ margin: 0, marginTop: 20 }} />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 20,
                      marginBottom: 10
                    }}
                  >
                    <QRCode value={config.CROWDSALE} size={150} />
                  </div>
                  <Text
                    style={{
                      color: 'red',
                      textAlign: 'center',
                      marginBottom: 5
                    }}
                  >
                    WARNING:
                    <br /> SEND ONLY{' '}
                    {eth.config.network === 'eth' ? 'ETH' : 'BNB'} TO THIS
                    ADDRESS
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      textAlign: 'center',
                      marginBottom: 5
                    }}
                  >
                    Set gas limit in metamask to 80 000 gas units manually.
                  </Text>
                  <Text
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      maxWidth: '100%'
                    }}
                  >
                    Remember: DO NOT send{' '}
                    {eth.config.network === 'eth' ? 'ETH' : 'BNB'} from
                    exchanges, only use wallets such as Metamask, Myetherwallet,
                    and other ones of which you are the seed phrase owner.
                  </Text>
                  <Hr style={{ margin: 0, marginTop: 10, marginBottom: 15 }} />
                  <Text
                    style={{
                      textAlign: 'center',
                      marginBottom: 0
                    }}
                  >
                    Official BuffSwap
                  </Text>
                  <Text style={{ textAlign: 'center' }}>
                    {eth.config.network === 'eth' ? 'ERC-20' : 'BEP-20'}{' '}
                    Exchange Address
                  </Text>
                  <Text
                    style={{
                      fontWeight: 500,
                      overflowWrap: 'break-word',
                      fontSize: '14px',
                      textAlign: 'center'
                    }}
                  >
                    {config.CROWDSALE}
                  </Text>
                  <div
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Button
                      text={'COPY CROWDSALE ADDRESS'}
                      style={{
                        fontWeight: 500,
                        width: '80%',
                        fontSize: 14,
                        padding: '12px 6px'
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(config.CROWDSALE)
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 10,
                      cursor: 'pointer'
                    }}
                    onClick={(e: any) => {
                      window.open(
                        `
                      ${eth.config.explorerUrl}/address/${eth.config.crowdsaleMainAddress}
                      `,
                        '_blank'
                      )
                    }}
                  >
                    <Text
                      style={{
                        marginBottom: 0,
                        color: 'var(--secondary)'
                      }}
                    >
                      go to{' '}
                      {eth.config.network === 'eth' ? 'EtherScan' : 'BscScan'}
                    </Text>
                    <RightOutlined
                      style={{
                        marginLeft: 10,
                        color: 'var(--secondary)'
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <TransactionModal
              tx={tx}
              inputValue={tokenInputValue}
              inputSymbol={localState.dropdownName as string}
              outputValue={buffSwapInputValue}
            />
          </div>
          <div
            style={{
              minWidth: width >= 930 ? '444px' : '330px',
              height: 'max-content',
              width: '100%',
              position: 'relative'
            }}
          >
            <BuyTokenStats />
            <InformationList setView={setView} />
          </div>
        </div>
      </section>
    </>
  )
}

export default BuyToken
