import styled from 'styled-components'

export const StatsBox = styled.div`
  background-color: #fff;
  border: 1px solid #e3e4eb;
  border-radius: 6;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 38.5185px 51.9185px rgba(0, 0, 0, 0.0425185),
    0px 8.14815px 13.2815px rgba(0, 0, 0, 0.0274815);
  margin-left: 30px;
  padding: 38px 40px;
  align-items: baseline;
  justify-content: center;
  min-width: 330px;

  @media (max-width: 930px) {
    & {
      margin-left: 0px;
    }
  }
`

export const StatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 10px;
  justify-content: space-between;
`

export const StatsColumn = styled.div<{ width: number }>`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  align-content: flex-start;
  width: ${(p) => `${p.width}%`};
`
