import styled from 'styled-components'

export const StyledProgressBarClaim = styled.div`
  width: 100%;
  background-color: rgba(29, 28, 57, 0.6);
  height: 3px;
  position: relative;
`

export const ProgressBarClaimFill = styled.div<{ percentage: number }>`
  position: absolute;
  background: #1d1c39;
  left: 0;
  top: -1px;
  bottom: 0;
  right: ${(p) => 100 - p.percentage}%;
  height: 5px;
`

export const StyledProgressBarVesting = styled.div`
  width: 100%;
  background-color: var(--secondary);
  height: 3px;
  position: relative;
`

export const ProgressBarVestingFill = styled.div<{ percentage: number }>`
  position: absolute;
  background: var(--primary);
  left: 0;
  top: -1px;
  bottom: 0;
  right: ${(p) => 100 - p.percentage}%;
  height: 5px;
`

export const StyledProgressDateStyled = styled.div`
  background-color: var(--secondary);
  width: 3px;
  position: relative;
`

export const ProgressBarDateFill = styled.div<{ percentage: number }>`
  position: absolute;
  background: var(--secondary);
  left: 0;
  top: 0;
  right: 0;
  bottom: ${(p) => 100 - p.percentage}%;
  width: 5px;
`
