import { Options } from './enums'

export const tokenPrecision = (token: Options) => {
  switch (token) {
    case Options.ETH:
      return 10 ** 18
    case Options.USDT:
      return 10 ** 6
    case Options.BNB:
      return 10 ** 18
    case Options.BUSD:
      return 10 ** 18
    case Options.BUFFS:
      return 10 ** 4
  }
}

export const calculateValue = (token: Options, value: number) => {
  try {
    return BigInt(Math.floor(value * tokenPrecision(token)))
  } catch (e) {
    console.error(e)
    return BigInt(0)
  }
}

export const trimInput = (input: string): string => {
  const value = input.slice(0, -1)
  return value
}

export const calculateOutputValue = (token: Options, value: bigint) => {
  try {
    return Number(value) / tokenPrecision(token)
  } catch {
    return 0
  }
}
