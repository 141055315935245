import React from 'react'
import { EthContext } from './EthProvider'

export type TxType =
  | 'increaseAllowance'
  | 'buyForNativeCoin'
  | 'buyForStablecoin'
  | 'claim'
  | 'stablecoinBatch'

export interface EthTransactionState {
  status: 'ready' | 'sending' | 'mining' | 'success' | 'failed' | 'rejected'
  hashes: string[]
  error: any
  init: (
    value: bigint,
    type: TxType,
    crowdsaleAddress?: string
  ) => Promise<void>
  clear: () => Promise<void>
  canClear: () => boolean
}

export const initialState = (): EthTransactionState => ({
  status: 'ready',
  hashes: [],
  error: null,
  init: async () => {},
  clear: async () => {},
  canClear: () => false
})

export const useEthTransaction = (): EthTransactionState => {
  const [state, setState] = React.useState(initialState())
  const { services } = React.useContext(EthContext)

  const init = async (
    value: bigint,
    type: TxType,
    crowdsaleAddress?: string
  ) => {
    if (state.status !== 'ready') return

    await execute(value, type, crowdsaleAddress)
  }

  const broadcast = async (
    value: bigint,
    type: TxType,
    crowdsaleAddress?: string
  ): Promise<string[]> => {
    if (type === 'increaseAllowance') {
      const tx = await services.write.createIncreaseAllowanceTx(
        value,
        crowdsaleAddress
      )
      const hash = await services.write.sendTx(tx)
      return [hash]
    }

    if (type === 'buyForNativeCoin') {
      const tx = await services.write.createBuyTx(value, crowdsaleAddress)
      const hash = await services.write.sendTx(tx)
      return [hash]
    }

    if (type === 'buyForStablecoin') {
      const tx = await services.write.createBuyForUSDTx(value, crowdsaleAddress)
      const hash = await services.write.sendTx(tx)
      return [hash]
    }

    if (type === 'claim') {
      const tx = await services.write.createClaimTx(value, crowdsaleAddress)
      const hash = await services.write.sendTx(tx)
      return [hash]
    }

    if (type === 'stablecoinBatch') {
      const increaseAllowanceTx =
        await services.write.createIncreaseAllowanceTx(value, crowdsaleAddress)
      const buyTx = await services.write.createBuyForUSDTx(
        value,
        crowdsaleAddress
      )
      return await services.write.sendMultipleTxes([increaseAllowanceTx, buyTx])
    }

    return []
  }

  const execute = async (
    value: bigint,
    type: TxType,
    crowdsaleAddress?: string
  ) => {
    setState((state) => ({ ...state, status: 'sending' }))

    try {
      const hashes = await broadcast(value, type, crowdsaleAddress)
      setState((state) => ({ ...state, status: 'mining', hashes }))

      await services.write.waitForTxes(hashes)

      setState((state) => ({ ...state, status: 'success' }))
    } catch (e: any) {
      console.error(e)

      if (e?.code === 4001) {
        setState((state) => ({ ...state, status: 'rejected' }))
      } else {
        setState((state) => ({
          ...state,
          status: 'failed',
          error: services.utils.getErrorMessage(e)
        }))
      }
    }
  }

  const canClear = () =>
    state.status === 'failed' ||
    state.status === 'success' ||
    state.status === 'rejected'

  const clear = async () => {
    if (canClear()) {
      setState(initialState())
    }
  }

  return { ...state, init, clear, canClear }
}
