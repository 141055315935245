import styled from 'styled-components'

const Container = styled.div`
  margin-top: 15px;
  padding: 0 5px;
  width: max-width;
  height: 100%;
  margin-left: 30px;

  @media (max-width: 930px) {
    margin-left: 0px;
  }
`

export default Container
