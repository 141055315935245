import React from 'react'
import AOS from 'aos'

export const useAOS = () => {
  React.useEffect(() => {
    const handle = setTimeout(() => {
      AOS.init({
        offset: Math.floor(window.innerHeight * 0.1),
        duration: 500,
        delay: 0,
        easing: 'ease-in-sine',
        once: true
      })

      AOS.refresh()
    }, 500)

    return () => clearTimeout(handle)
  }, [])
}
