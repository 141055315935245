import { MetaMaskState } from 'metamask-react/lib/metamask-context'
import React from 'react'
import { EthNetworkConfig, EthServices, EthUpdate } from './EthTypes'
import { EthReadService } from './services/EthReadService'

export const initialUpdate = (): EthUpdate => ({
  userLockedBalance: null,
  userNativeBalance: null,
  userUSDBalance: null,
  gasPrice: null,
  ethPrice: null,
  userBUFFSBalance: null,
  userAvailableBUFFS: null,
  totalLocked: null,
  userMaxBalance: null,
  contracts: []
})

export const useEthUpdate = (
  config: EthNetworkConfig,
  setValue: (value: EthUpdate) => void,
  services: EthServices,
  status: MetaMaskState['status']
) => {
  React.useEffect(() => {
    const fetch = (readService: EthReadService) => {
      readService
        .fetchData()
        .then(setValue)
        .catch((e) => {
          console.error(e)
          setValue(initialUpdate())
        })
    }

    fetch(services.read)

    const handle = setInterval(() => {
      fetch(services.read)
    }, config.updateInterval)

    return () => clearInterval(handle)
    // eslint-disable-next-line
  }, [status])
}
