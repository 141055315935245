import React from 'react'

import { useEth } from '../../../../../Logic/Eth/EthProvider'

import {
  StyledContainer,
  ProgressBarFill,
  StyledProgressBar,
  StyledStats,
  StyledLabel,
  StyledValue
} from './ProgressBar.styled'

const ProgressBar = () => {
  const eth = useEth()

  return (
    <StyledContainer>
      <StyledProgressBar>
        <ProgressBarFill percentage={Math.min(eth.saleProgress, 100)} />
      </StyledProgressBar>
      <StyledStats>
        <StyledLabel>Tokens sold:</StyledLabel>
        <StyledValue>{eth.saleProgress}%</StyledValue>
      </StyledStats>
    </StyledContainer>
  )
}

export default ProgressBar
