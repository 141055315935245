import React from 'react'
// import { presets } from '../../../../Logic/Eth/EthPresets'
import { useEth } from '../../../../Logic/Eth/EthProvider'
// import { DownOutlined } from '@ant-design/icons'
import './dropdown.css'
import './network-select.css'
export interface NetworkSelectProps {
  style?: React.CSSProperties
}

// const Select = styled.select`
//   display: inline-block;
//   border-radius: 32px;
//   border: solid var(--secondary) 1px;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 150%;
//   color: #8e90a6;
//   padding: 4px 12px;
//   outline: none;
// `
const NetworkSelect: React.FC<NetworkSelectProps> = ({ style }) => {
  const [value, setValue] = React.useState<'eth' | 'bsc'>('eth')
  const { config } = useEth()
  const [isShown, setIsShown] = React.useState<boolean>(false)
  // const onChange = (e: string) => {
  //   const network = e

  //   if (network === 'eth') {
  //     switchConfig(presets.eth)
  //   }

  //   if (network === 'bsc') {
  //     switchConfig(presets.bnb)
  //   }

  //   setValue(network as any)
  // }

  React.useEffect(() => {
    if (config.network === 'eth') {
      setValue('eth')
    }

    if (config.network === 'bsc') {
      setValue('bsc')
    }
  }, [config.network])
  // const strValue = (num: bigint | null) => {
  //   if (!num) return '0'

  //   return (Number(num) / 10 ** 18).toFixed(2)
  // }
  const DropdownHandler = () => {
    setIsShown(!isShown)
  }
  return (
    <div style={style} className="network-select">
      <div
        style={{
          backgroundColor: '#fff',
          border: '1px solid var(--secondary)',
          borderRadius: "16px",
          width: "max-content",
          color: 'var(--secondary)',
          padding: '4px 12px',
          position: 'relative'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={DropdownHandler}
        >
          <span style={{ alignItems: 'center', display: 'flex' }}>
            {/* <DownOutlined style={{ marginRight: 10 }} /> */}
            {value === 'eth' ? 'Ethereum' : 'Binance'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default NetworkSelect
