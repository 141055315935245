import React from 'react'

import { Container, StyledLabel, StyledContent } from './BuyTokenStat.styled'

interface BuyTokenStatProps {
  label: string
  content: string
}

const BuyTokenStat: React.FC<BuyTokenStatProps> = ({ label, content }) => {
  return (
    <Container>
      <StyledLabel>{label}</StyledLabel>
      <StyledContent>{content}</StyledContent>
    </Container>
  )
}

export default BuyTokenStat
