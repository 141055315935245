import styled from 'styled-components'

const Heading = styled.h2`
  color: var(--primary);
  font-size: 48px;
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 34px;
  }
`

export default Heading
