import React, { Dispatch, SetStateAction } from 'react'
import BuyToken from './BuyToken'
import { ViewType } from './components/ViewController/ViewController'
import BalanceDashboard from './components/BalanceDashboard/BalanceDashboard'
import styled from 'styled-components'
import SwapNavbar from './components/SwapNavbar/SwapNavbar'
import VideoPlayer from '../../Components/VideoPlayer'
import { Modal } from 'react-bootstrap'
import { BuyTokenInterface, Options, TabNames, Tokens } from './utils/enums'

const Section = styled.section`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`
export const defaultState: BuyTokenInterface = {
  activeKey: TabNames.BSC,
  dropdownName: Options.BNB,
  dropdownFirstOption: Options.BNB,
  dropdownSecondOption: Options.BUSD,
  firstInputError: '',
  secondInputError: '',
  token: Tokens.Native
}

const BuyTokensMainView: React.FC = () => {
  const [view, setView] = React.useState<ViewType>('home')
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const handleModal = (e: any) => {
    setIsModalOpen(!isModalOpen)
  }
  const [localState, setLocalState] =
    React.useState<BuyTokenInterface>(defaultState)

  return (
    <React.Fragment>
      <SwapNavbar view={view} setView={setView} handleModal={handleModal} />
      <Section>
        {view === 'buy' ? (
          <BuyToken
            setView={setView}
            localState={localState}
            setLocalState={setLocalState}
          />
        ) : (
          <BalanceDashboard
            localState={localState}
            setLocalState={setLocalState}
          />
        )}
      </Section>
      <ModalHowToBuy
        isModalOpen={isModalOpen}
        handleModal={handleModal}
        setIsModalOpen={setIsModalOpen}
      />
    </React.Fragment>
  )
}

export default BuyTokensMainView

interface IMHowToBuy {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  handleModal: (e: any) => void
}

const ModalHowToBuy: React.FC<IMHowToBuy> = ({
  isModalOpen,
  setIsModalOpen,
  handleModal
}) => {
  return (
    <Modal
      className="modal-video-HTB  modal-content-HBT"
      show={isModalOpen}
      style={{ height: '100%', border: 'none' }}
      onHide={() => setIsModalOpen(false)}
      size="xl"
      backdropClassName="modal-backdrop foo-modal-backdrop in modal-how-to-buy"
      contentClassName="modal-content-video-HTB"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {/* <CloseOutlined
          style={{
            color: 'var(--secondary)',
            justifyContent: 'flex-start',
            display: 'flex',
            fontSize: 24,
            margin: 20
          }}
          onClick={(e) => handleModal(e)}
        /> */}
        <VideoPlayer id="alzfCYvydps" data-aos="fade-up" />
      </Modal.Body>
    </Modal>
  )
}
