import styled from 'styled-components'

export interface BackdropProps {
  visible: boolean
  onDismiss: () => void
}

const Styled = styled.div`
  background-color: #000;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  z-index: 20;
`

const Backdrop: React.FC<BackdropProps> = (props) => {
  return props.visible ? <Styled onClick={props.onDismiss} /> : null
}

export default Backdrop
