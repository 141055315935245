import { useEth } from '../../../Logic/Eth/EthProvider'
import styled from 'styled-components'
import { Alert } from 'react-bootstrap'
import Button from '../../../Components/Button'

export const Styled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  & .box {
    width: 100%;
    min-width: 200px;
    max-width: 400px;
  }

  & button {
    margin: auto;
    font-size: 18px;
    border-radius: 16px;
  }
`
// Requires parent element to have position relative style
const ClaimOverlay: React.FC = () => {
  const { metamask, error } = useEth()
  if (metamask.status === 'connected' && error === null) return null

  const getErrorText = (error: string) => {
    if (error === 'invalid_chain_id') {
      return 'Invalid network, select BSC Mainnet'
    }

    return error
  }

  return (
    <Styled>
      <div className="box">
        {metamask.status !== 'connected' && (
          <Alert variant="danger">Metamask is not connected</Alert>
        )}
        {error && metamask.status === 'connected' && (
          <Alert variant="danger">{getErrorText(error)}</Alert>
        )}
        {metamask.status !== 'connected' && (
          <Button
            text="Connect wallet"
            style={{ margin: 'auto', fontSize: 18, borderRadius: 16 }}
            onClick={metamask.connect}
          />
        )}
      </div>
    </Styled>
  )
}

export default ClaimOverlay
