import { EthUpdate } from './EthTypes'

export const getUserClaimRatio = (update: EthUpdate) => {
  const left = update.userLockedBalance
  const max = update.userMaxBalance

  if (left === null || max === null) {
    return { claimed: 0, left: 0 }
  }

  if (max === 0n) return { claimed: 0, left: 0 }

  const ratio = (Number(left) / Number(max)) * 100

  return {
    claimed: Math.round(100 - ratio),
    left: Math.round(ratio)
  }
}
