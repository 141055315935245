import React from 'react'
import styled from 'styled-components'
import { EthTransactionState } from '../../../../Logic/Eth/EthTransaction'
import Backdrop from './Backdrop'
import ErrorContent from './ErrorContent'
import LoadingContent from './LoadingContent'
import SuccessContent from './SuccessContent'
import TransactionModalWrapper from './ModalWrapper'
import Spinner from '../../../../Components/Spinner'

export interface TransactionModalProps {
  tx: EthTransactionState
  type?: 'buy' | 'claim' | 'increaseAllowance'
  inputValue?: string
  inputSymbol?: string
  outputValue?: string
  onDismiss?: () => void
  customMessage?: string
}

const matchTopText = (status: EthTransactionState['status']): string => {
  if (status === 'success') return 'Transaction completed!'
  if (status === 'failed') return 'Something went wrong...'
  if (status === 'rejected') return 'Transaction rejected'
  if (status === 'sending') return 'Waiting for user input...'
  if (status === 'mining') return 'Waiting for transaction confirmation...'
  return 'Invalid status'
}

const getBuyText = (values: TransactionModalProps): string => {
  return `swap: ${values.inputValue} ${values.inputSymbol} to ${values.outputValue} BUFFS`
}

const getClaimText = (values: TransactionModalProps): string => {
  return `claim: ${values.inputValue} BUFFS`
}

const getIncreaseAllowanceText = (values: TransactionModalProps): string => {
  return `Allow to spend ${values.inputValue} ${values.inputSymbol}`
}

const matchIcon = (status: EthTransactionState['status']) => {
  if (status === 'success') {
    return <img src="/assets/images/swap/tx-tick.svg" alt="tick" />
  }

  if (status === 'failed' || status === 'rejected') {
    return (
      <img
        src="/assets/images/swap/tx-warning.svg"
        alt="warning"
        style={{ marginBottom: -30 }}
      />
    )
  }

  return <Spinner size={80} color="var(--secondary)" width={30} />
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 25;

  & .top {
    font-weight: normal;
    font-size: 14px;
    color: #616d6d;
    margin-bottom: 2px;
  }

  & .desc {
    font-weight: normal;
    font-size: 12px;
    color: rgba(97, 109, 109, 0.7);
    margin-bottom: 20px;
  }

  & .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin-bottom: 12px;
  }
`

const getText = (props: TransactionModalProps) => {
  if (props.customMessage) return props.customMessage

  switch (props.type) {
    case 'claim':
      return getClaimText(props)
    case 'increaseAllowance':
      return getIncreaseAllowanceText(props)
    default:
      return getBuyText(props)
  }
}

const TransactionModal: React.FC<TransactionModalProps> = (props) => {
  const visible = props.tx.status !== 'ready'
  const onDismiss = props.onDismiss ?? props.tx.clear

  React.useEffect(() => {
    if (props.tx.status !== 'rejected') return

    const handle = setTimeout(() => {
      onDismiss()
    }, 3000)

    return () => clearTimeout(handle)
    // eslint-disable-next-line
  }, [props.tx.status])

  return (
    <React.Fragment>
      <TransactionModalWrapper
        visible={visible}
        onDismiss={onDismiss}
        allowToDismiss={props.tx.canClear()}
      >
        <Styled>
          <div className="icon-wrapper">{matchIcon(props.tx.status)}</div>
          <span className="top">{matchTopText(props.tx.status)}</span>
          <span className="desc">{getText(props)}</span>
          <LoadingContent {...props.tx} />
          <ErrorContent {...props.tx} />
          <SuccessContent {...props.tx} />
        </Styled>
      </TransactionModalWrapper>
      <Backdrop visible={visible} onDismiss={onDismiss} />
    </React.Fragment>
  )
}

export default TransactionModal
