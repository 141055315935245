import { MetaMaskState } from 'metamask-react/lib/metamask-context'
import React from 'react'
// import { useHistory } from 'react-router'
import styled from 'styled-components'
import BlinkingLogo from '../../../../Components/BlinkingLogo'
import Button from '../../../../Components/Button'
import { useEth } from '../../../../Logic/Eth/EthProvider'
import Address from './Address'
import ViewController, { ViewType } from '../ViewController/ViewController'
import BalanceInfo from './BalanceInfo'
import NetworkSelect from './NetworkSelect'
// import NetworkSelect from './NetworkSelect'
import { NEW_BUFFSWAP_WEBSITE } from '../../../../constants'

export interface SwapNavbarProps {
  view: ViewType
  setView: (value: ViewType) => void
  handleModal: (e: any) => void
}

const NavbarContainer = styled.div`
  background-color: white;
  padding: 15px 20px;
  width: 100%;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.1));
`

const ButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 450px) {
    height: 110px;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
  }
`

const NavbarBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: auto;
  min-height: 20px;
  @media (max-width: 1150px) {
    /* position: static; */
  }
  @media (max-width: 1360px) {
    flex-direction: column;
    height: 170px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    height: 270px;
  }
`

const getButtonText = (status: MetaMaskState['status']): string => {
  if (status === 'connecting') return 'Connecting...'
  if (status === 'connected') return 'Wallet Connected'
  if (status === 'notConnected') return 'Connect Wallet'
  if (status === 'initializing') return 'Loading...'
  if (status === 'unavailable') return 'Unavailable'

  return 'Error'
}

const SwapNavbar: React.FC<SwapNavbarProps> = (props) => {
  // const history = useHistory()
  const eth = useEth()

  return (
    <NavbarContainer>
      <NavbarBox>
        <BlinkingLogo
          onClick={() => (window.location.href = NEW_BUFFSWAP_WEBSITE)}
        />
        {/* <BlinkingLogo onClick={() => history.push('/')} /> */}
        <ViewController
          value={props.view}
          setValue={props.setView}
          style={{}}
        />
        <ButtonsContainer style={{}}>
          {/* <Button
            variant={'primary'}
            text={'How to buy?'}
            onClick={(e) => props.handleModal(e)}
            style={{ marginRight: 10 }}
          /> */}
          {eth.metamask.status === 'connected' && (
            <React.Fragment>
              <NetworkSelect />
              <BalanceInfo
                style={{
                  marginRight: 10,
                  display: 'flex',
                  alignItems: 'center',
                  width: 'max-content',
                  justifyContent: 'center'
                }}
              />
              <Address
                value={eth.services.utils.userAddress}
                style={{
                  marginRight: 15,
                  display: 'flex',
                  alignItems: 'center'
                }}
              />
            </React.Fragment>
          )}
          {eth.metamask.status !== 'connected' && (
            <Button
              text={getButtonText(eth.metamask.status)}
              disabled={eth.metamask.status !== 'notConnected'}
              onClick={eth.metamask.connect}
            />
          )}
        </ButtonsContainer>
      </NavbarBox>
    </NavbarContainer>
  )
}

export default SwapNavbar
