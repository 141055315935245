import { EthService } from './EthService'
import { PaymentToken } from '../EthTypes'
import { config as globalConfig } from '../EthConfig'

export type RegisterAssetResult = 'success' | 'failed' | 'rejected'

export interface RegisterAssetInput {
  address: string
  symbol: string
  decimals: number
  image: string
}

export class EthUtilsService extends EthService {
  // Call this at the start of the app
  static copyRefToSession() {
    const query = new URLSearchParams(window.location.search)
    const ref = query.get('ref')

    if (!ref) return

    window.sessionStorage.setItem('ref', ref)
  }

  getDiscount() {
    const ref = window.sessionStorage.getItem('ref')
    if (!ref) return null

    const entry = globalConfig.discounts.find((entry) => {
      return entry.ref === ref && entry.network === this.config.network
    })

    return entry ?? null
  }

  getRate() {
    const discount = this.getDiscount()
    if (!discount) return this.config.rate

    return discount.rate
  }

  getCrowdsaleAddress() {
    const discount = this.getDiscount()
    if (!discount) return this.config.crowdsaleMainAddress

    return discount.contract
  }

  async registerAsset(input: RegisterAssetInput): Promise<RegisterAssetResult> {
    try {
      const added = await this.eth.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: input
        }
      })

      return added ? 'success' : 'rejected'
    } catch (e: any) {
      console.error(e)
      return 'failed'
    }
  }

  calculateTokenAmount(
    value: bigint,
    token: PaymentToken,
    nativePrice: bigint
  ) {
    try {
      const { usdDecimals } = this.config
      const price = token === 'native' ? nativePrice : 100000000n
      const paymentDecimals = token === 'native' ? 18 : usdDecimals

      return (
        (price * value * 10n ** BigInt(globalConfig.tokenDecimals)) /
        this.getRate() /
        10n ** BigInt(paymentDecimals)
      )
    } catch (e) {
      console.error(e)
      return 0n
    }
  }

  calculatePaymentAmount(
    value: bigint,
    token: PaymentToken,
    nativePrice: bigint
  ) {
    try {
      const { usdDecimals } = this.config

      const price = token === 'native' ? nativePrice : 100000000n
      const paymentDecimals = token === 'native' ? 18 : usdDecimals

      return (
        (value * this.getRate() * 10n ** BigInt(paymentDecimals)) /
        10n ** BigInt(globalConfig.tokenDecimals) /
        price
      )
    } catch (err) {
      return 0n
    }
  }

  getErrorMessage(err: any): string {
    const prefix = 'Returned error: '

    if (err?.message?.includes(prefix)) {
      return err.message.replace(prefix, '')
    }

    if (err?.message) return err.message

    return 'unknown_error'
  }

  validateProvider = (): string | null => {
    const { eth, config } = this

    if (!eth) return 'no_provider'
    if (!eth.selectedAddress) return 'no_address'
    if (eth.chainId !== config.chainId) return 'invalid_chain_id'

    if (!eth.isMetaMask) return 'invalid_provider'
    if (!eth.request) return 'invalid_provider'

    return null
  }
}
