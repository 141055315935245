import styled from 'styled-components'

export interface WalletWarningProps {
  text: string
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  width: 80%;
  padding: 12px;
  border: solid #ea8888 1px;
  margin-top: 10px;

  & span {
    font-size: 18px;
    font-weight: 400;
    color: #1d1c39;
  }
`

const WalletWarning: React.FC<WalletWarningProps> = (props) => {
  return (
    <Styled>
      <img
        src="/assets/images/swap/metamask-warning.svg"
        alt="warning"
        width={42}
        height={42}
      />
      <span>{props.text}</span>
    </Styled>
  )
}

export default WalletWarning
