import styled from 'styled-components'
import React from 'react'

export interface BlinkingLogoProps {
  onClick?: () => any
}

const Container = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  transition: transform 0.1s linear;

  &:hover {
    transform: scale(1.05);
  }
`

const Img = styled.img<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${(p) => (p.visible ? 1 : 0)};
`

const BlinkingLogo: React.FC<BlinkingLogoProps> = (props) => {
  const [blink, setBlink] = React.useState(false)

  return (
    <Container
      onMouseEnter={() => setBlink(true)}
      onMouseLeave={() => setBlink(false)}
      onClick={props.onClick}
    >
      <Img
        src="/assets/images/common/blinking-logo-on.svg"
        alt="logo"
        visible={blink}
      />
      <Img
        src="/assets/images/common/blinking-logo-off.svg"
        alt="logo"
        visible={!blink}
      />
    </Container>
  )
}

export default BlinkingLogo
