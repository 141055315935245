import { Link } from 'react-router-dom'

const NotFound: React.FC = () => (
  <section
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100vh'
    }}
  >
    <h1 style={{ fontSize: 100, fontWeight: 700 }}>404</h1>
    <h2 style={{ fontSize: 25, fontWeight: 700 }}>Not found</h2>
    <Link to="/">Go Home</Link>
  </section>
)

export default NotFound
