import Heading from '../../../Components/Heading'
import Text from '../../../Components/Text'
import Space from '../../../Components/Space'
import React from 'react'
import styled, { keyframes } from 'styled-components'

export interface BettingTraitProps {
  image: string
  heading?: string
  text: string
  animationDelay: string
}

const floating = keyframes`
  0% { transform: translateY(0px) }
  50% { transform: translateY(-8px) }
  100% { transform: translateY(0px) }
`

const Img = styled.img`
  animation: ${floating} 4s infinite ease-in-out;
`

const CustomHeading = styled(Heading)`
  font-size: 24px;

  @media (max-width: 768px) {
    text-align: center;
  }
`

const CustomText = styled(Text)`
  @media (max-width: 768px) {
    text-align: center;
  }
`

const Container = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 420;
    margin: auto;
  }
`

const BettingTrait: React.FC<BettingTraitProps> = (props) => {
  return (
    <Container>
      <Img
        src={props.image}
        alt="betting-trait"
        style={{ animationDelay: props.animationDelay ?? '0s' }}
      />
      <Space height={40} />
      {props.heading && (
        <React.Fragment>
          <CustomHeading>{props.heading}</CustomHeading>
          <Space height={10} />
        </React.Fragment>
      )}
      <CustomText>{props.text}</CustomText>
    </Container>
  )
}

export default BettingTrait
