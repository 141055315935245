import moment from 'moment'
import React from 'react'
import Text from '../../../../Components/Text'
import { ReactComponent as CheckVesting } from '../../../../svg/claim/checkVesting.svg'
interface IProps {
  date: number[]
  hasBorderBottom: boolean
}

export const ProgressDateBar: React.FC<IProps> = (props) => {
  const dateNow = new Date()

  return (
    <div
      style={{
        borderRight: '1px solid var(--secondary',
        borderBottom: props.hasBorderBottom
          ? '1px solid var(--secondary)'
          : 'none',
        width: 30,
        height: 'max-content',
        padding: 3
      }}
    >
      {props.date.map((i) => {
        return (
          <div key={i} style={{ position: 'relative', height: 20 }}>
            <div style={{ display: 'flex' }}>
              {moment(dateNow).unix() >= i && (
                <CheckVesting
                  style={{
                    top: 3,
                    position: 'absolute',
                    left: '22px'
                  }}
                />
              )}
              <Text
                style={{
                  position: 'absolute',
                  left: '40px',
                  fontWeight: 400,
                  fontSize: 10,
                  marginBottom: 8
                }}
              >
                {moment.unix(i).format('DD.MM.YYYY')}
              </Text>
            </div>
          </div>
        )
      })}
    </div>
  )
}
