import React from 'react'
import { Spinner, Toast } from 'react-bootstrap'
import { useEth } from '../../../Logic/Eth/EthProvider'
import {
  EthTransactionState,
  useEthTransaction
} from '../../../Logic/Eth/EthTransaction'
import { EthUpdate } from '../../../Logic/Eth/EthTypes'
import { shortAddress } from '../../../Logic/utils/shortAddress'
import './claiming.css'
import ClaimOverlay from './ClaimOverlay'

type DType = EthUpdate['contracts'][0]

const bnToString = (bn: bigint | null) => {
  if (!bn) return '0.0000'
  return (Number(bn) / 10 ** 4).toFixed(4)
}

const getTotal = (data: DType) => bnToString(data.maxBalance)

const getClaimedAlready = (data: DType) => {
  if (!data.maxBalance && typeof data.maxBalance !== 'bigint') return '0.0000'
  if (!data.balance && typeof data.balance !== 'bigint') return '0.0000'

  return bnToString(data.maxBalance - data.balance)
}

const getLeftToClaim = (data: DType) => bnToString(data.balance)

const getAvailableToClaimNow = (data: DType) =>
  bnToString(data.maxTokensToUnlock)

const ClaimDev = () => {
  const { update } = useEth()
  const tx = useEthTransaction()
  const [spinnerRunning, setSpinnerRunning] = React.useState(false)
  const [columnAddress, setColumnAddress] = React.useState<string | undefined>(
    ''
  )
  const [toastopen, setToastOpen] = React.useState(false)
  const [toastClass, setToastClass] = React.useState('')

  const claim = async (address: string | undefined, value: bigint | null) => {
    if (!value || !address) return

    // console.warn('Correct claim value', value)

    await tx.init(value, 'claim', address)
  }

  const buttonDisabled = (contract: any) => {
    return (
      !contract.address || !contract.maxTokensToUnlock || tx.status !== 'ready'
    )
  }

  const getSpinnerState = (tx: EthTransactionState) => {
    return !(
      tx.status === 'success' ||
      tx.status === 'failed' ||
      tx.status === 'rejected'
    )
  }

  const getSpinnerAfterState = (tx: EthTransactionState) => {
    return tx.status === 'ready'
  }

  React.useEffect(() => {
    if (getSpinnerAfterState(tx) || toastopen) {
      setSpinnerRunning(false)
    } else if (getSpinnerState(tx)) {
      setSpinnerRunning(true)
    } else {
      setToastOpen(true)
    }

    if (tx.status === 'success') {
      setToastClass('success')
    } else if (tx.status === 'rejected') {
      setToastClass('rejected')
    } else if (tx.status === 'failed') {
      setToastClass('failed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tx])

  const createToast = (toastOpen: boolean) => {
    return (
      !getSpinnerState(tx) &&
      toastOpen && (
        <div className={`toast-holder`}>
          <Toast
            delay={3000}
            autohide
            show={true}
            className={`toast ${toastClass}`}
            onClose={() => {
              tx.clear()
              setToastOpen(false)
              setSpinnerRunning(false)
            }}
          >
            <Toast.Header className={toastClass}>
              <strong className="me-auto">Transaction status</strong>
            </Toast.Header>
            <Toast.Body>{tx.status}</Toast.Body>
          </Toast>
        </div>
      )
    )
  }

  return (
    <section id="claiming" style={{ position: 'relative' }}>
      <ClaimOverlay />
      <div className="row">
        {update.contracts.map((contract) => (
          <div
            className="col col-12 col-md-4 claim-column"
            key={contract.address}
          >
            <label className="contract-address-label">
              smart contract address:
            </label>
            <div className="contract-address">
              {shortAddress(4, contract.address)}
            </div>
            <label className="claiming-label">Bought in total</label>
            <p className="claiming-value">{getTotal(contract)}</p>
            <hr className="claiming-hr" />

            <label className="claiming-label">Claimed</label>
            <p className="claiming-value">{getClaimedAlready(contract)}</p>

            <hr className="claiming-hr" />

            <label className="claiming-label">Left to claim</label>
            <p className="claiming-value">{getLeftToClaim(contract)}</p>
            <hr className="claiming-hr" />

            <label className="claiming-label">Available to claim now</label>
            <p className="claiming-value">{getAvailableToClaimNow(contract)}</p>

            <button
              onClick={() => {
                claim(contract.address, contract.maxTokensToUnlock)
                setColumnAddress(contract.address)
              }}
              disabled={buttonDisabled(contract)}
              className={
                buttonDisabled(contract)
                  ? 'claim-button disabled'
                  : 'claim-button'
              }
            >
              Claim
              {spinnerRunning && columnAddress === contract.address && (
                <Spinner
                  animation={'border'}
                  size="sm"
                  style={{ marginLeft: '10px' }}
                />
              )}
            </button>
          </div>
        ))}
      </div>
      {createToast(toastopen)}
    </section>
  )
}

export default ClaimDev
