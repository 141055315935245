import { EthTransactionState } from '../../../../Logic/Eth/EthTransaction'

const ErrorContent: React.FC<EthTransactionState> = (props) => {
  if (props.status !== 'failed') return null

  return (
    <div
      style={{
        border: 'solid #EA8888 2px',
        borderRadius: 22,
        padding: 10,
        margin: '0 5px'
      }}
    >
      <span style={{ fontSize: 12 }}>{props.error}</span>
    </div>
  )
}

export default ErrorContent
