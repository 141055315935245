import InformationBar from '../../InformationBar'
import Container from './Container.styled'
import { ViewType } from '../ViewController/ViewController'
import React from 'react'

interface InformationListProps {
  setView: React.Dispatch<ViewType>
}

const InformationList: React.FC<InformationListProps> = ({ setView }) => {
  return (
    <Container>
      <InformationBar text={'Form works properly only via metamask.'} />
      <InformationBar
        text={
          'You will be able to claim your Buff-Swap tokens to the buying wallet from the'
        }
        onClick={() => {
          setView('home')
        }}
        chainlink="Claim Page."
      />
      <InformationBar
        text={
          'Tokens will be unlocking according to Vesting schedule which is described in'
        }
        link={`${window.location.origin}/assets/litepaper/buff-swap-litepaper-LAST.pdf`}
        chainlink="Litepaper."
      />
      <InformationBar
        text={
          'Check if your address appears on the list after completing the transaction, click the Bscscan icon in the transaction view'
        }
      />
    </Container>
  )
}

export default InformationList
