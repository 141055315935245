export interface BuyTokenInterface {
  activeKey: TabNames
  dropdownName: Options.ETH | Options.BNB | Options.USDT | Options.BUSD
  dropdownFirstOption: Options.ETH | Options.BNB
  dropdownSecondOption: Options.USDT | Options.BUSD
  firstInputError: string
  secondInputError: string
  // isSwapDisabled: boolean
  token: Tokens
}

export enum TabNames {
  BSC = 'BSC',
  ETH = 'ETH'
}

export enum Options {
  ETH = 'ETH',
  BNB = 'BNB',
  USDT = 'USDT',
  BUSD = 'BUSD',
  BUFFS = 'BUFFS'
}

export enum Status {
  READY = 'Ready',
  SENDING = 'Sending',
  FAILED = 'Failed'
}

export enum Tokens {
  Native = 'native',
  StableCoin = 'stable_coin'
}
