import { EthService } from './EthService'
import { TransactionConfig } from 'web3-core'
import { Contract } from 'web3-eth-contract'
import Web3 from 'web3'
import CrowdsaleAbi from '../abi/CrowdsaleAbi.json'

export class EthWriteService extends EthService {
  private getCrowdsaleContract(crowdsaleAddress?: string) {
    if (!crowdsaleAddress) return this.crowdsaleMainContract

    return this.initContract(CrowdsaleAbi, crowdsaleAddress)
  }

  async createIncreaseAllowanceTx(
    value: bigint,
    crowdsaleAddress?: string
  ): Promise<TransactionConfig> {
    return await this.createContractTx(this.usdContract, 'approve', [
      crowdsaleAddress ?? this.config.crowdsaleMainAddress,
      value.toString()
    ])
  }

  async createBuyForUSDTx(
    value: bigint,
    crowdsaleAddress?: string
  ): Promise<TransactionConfig> {
    return await this.createContractTx(
      this.getCrowdsaleContract(crowdsaleAddress),
      'buyForUSD',
      [value.toString()]
    )
  }

  async createBuyTx(
    value: bigint,
    crowdsaleAddress?: string
  ): Promise<TransactionConfig> {
    const params: TransactionConfig = {
      to: crowdsaleAddress ?? this.config.crowdsaleMainAddress,
      from: this.eth.selectedAddress,
      value: value.toString()
    }

    const gas = await this.estimateGas(params)

    return { ...params, gas }
  }

  private async estimateGas(config: TransactionConfig) {
    return await this.web3.eth.estimateGas(config).then((r) => r.toString(16))
  }

  private async createContractTx(
    contract: Contract,
    method: string,
    args: any[],
    value?: bigint
  ): Promise<TransactionConfig> {
    const data = contract.methods[method](...args).encodeABI()

    const params: TransactionConfig = {
      to: contract.options.address,
      from: this.userAddress!,
      value: value ? value.toString() : undefined,
      data
    }

    const gas = await this.estimateGas(params)

    return { ...params, gas }
  }

  async createClaimTx(
    value: bigint,
    crowdsaleAddress?: string
  ): Promise<TransactionConfig> {
    return await this.createContractTx(
      this.getCrowdsaleContract(crowdsaleAddress),
      'claim',
      [value.toString()]
    )
  }

  async sendMultipleTxes(params: TransactionConfig[]): Promise<string[]> {
    // Web3 has missing typings
    const web3 = new Web3(this.eth as any) as any
    const batch = new web3.BatchRequest()

    const hashes: string[] = new Array(params.length)

    const callbackFactory = (
      index: number,
      resolve: (value: string[] | PromiseLike<string[]>) => void,
      reject: (reason?: any) => void
    ) => {
      return (err: any, hash: string) => {
        if (err) return reject(err)

        hashes[index] = hash

        if (hashes.every((hash) => hash)) {
          return resolve(hashes)
        }
      }
    }

    return new Promise<string[]>((resolve, reject) => {
      params.forEach((config, index) => {
        const cb = callbackFactory(index, resolve, reject)
        batch.add(web3.eth.sendTransaction.request(config, cb))
      })

      batch.execute()
    })
  }

  waitForTx(txHash: string) {
    const INTERVAL = 2000

    return new Promise<void>((resolve) => {
      const handle = setInterval(async () => {
        const tx = await this.web3.eth
          .getTransactionReceipt(txHash)
          .catch(() => null)

        if (!tx?.blockHash) return

        clearInterval(handle)
        resolve()
      }, INTERVAL)
    })
  }

  async waitForTxes(hashes: string[]) {
    for (const hash of hashes) {
      await this.waitForTx(hash)
    }
  }

  async sendTx(params: TransactionConfig): Promise<string> {
    return await this.eth.request({
      method: 'eth_sendTransaction',
      params: [{ ...params }]
    })
  }
}
