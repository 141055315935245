import { EthTransactionState } from '../../../../Logic/Eth/EthTransaction'

const LoadingContent: React.FC<EthTransactionState> = (props) => {
  if (props.status !== 'sending' && props.status !== 'mining') return null

  return (
    <div style={{ fontSize: 12, color: '#616D6D' }}>
      {props.status === 'sending' ? 'Confirm transaction in your wallet' : ''}
    </div>
  )
}

export default LoadingContent
