import { EthConfig } from './EthTypes'

export const config: EthConfig = {
  // bsc_test: {
  //   network: 'bsc',
  //   chainId: '0x61',
  //   rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  //   explorerUrl: 'https://testnet.bscscan.com',
  //   // Main crowdsale address. This address will be used to call buy transactions,
  //   // unless there is a discount address available
  //   crowdsaleMainAddress: '0x1a2380461B2DF9d686C64d75421C45Eb3A18B6E4',
  //   tokenAddress: '0xaAF24f1d1fE59438a890f9CF5CA53886EaA2daCf',
  //   usdAddress: '0xbCF72e25D7cCa3bF140655e4d8F1d778968C8C62',
  //   usdDecimals: 18,
  //   updateInterval: 30000,
  //   rate: 10000000n,
  //   nativeTxGas: 15010499,
  //   nativeTokenSymbol: 'BNB',
  //   stableCoinSymbol: 'BUSD',
  //   // ALL crowdsale addresses should be here. Used for fetching balances and locked values
  //   crowdsaleAddresses: ['0x1a2380461B2DF9d686C64d75421C45Eb3A18B6E4']
  // },
  bsc: {
    network: 'bsc',
    chainId: '0x38',
    rpcUrl: 'https://bsc-dataseed.binance.org',
    explorerUrl: 'https://bscscan.com',
    // Main crowdsale address. This address will be used to call buy transactions,
    // unless there is a discount address available
    crowdsaleMainAddress: '0xd829486b391FDf8b5e7967570689d4D85a62Da3E',
    tokenAddress: '0x140b890bf8e2fe3e26fcd516c75728fb20b31c4f',
    usdAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    usdDecimals: 18,
    updateInterval: 30000,
    rate: 10000000n,
    nativeTxGas: 15010499,
    nativeTokenSymbol: 'BNB',
    stableCoinSymbol: 'BUSD',
    // ALL crowdsale addresses should be here. Used for fetching balances and locked values
    crowdsaleAddresses: [
      '0xd829486b391FDf8b5e7967570689d4D85a62Da3E',
      '0x982887130a1E4040D6d414FAa4301a742156FB46',
      '0xed1B150F589c5E3d4b7d5CEe3Fa4177edE3F63FA'
    ]
  },
  eth: {
    network: 'eth',
    chainId: '0x1',
    rpcUrl: 'https://mainnet.infura.io/v3/d35635cce9c34253a3926bd4d256cf3a',
    explorerUrl: 'https://etherscan.io',
    crowdsaleMainAddress: '0xed1B150F589c5E3d4b7d5CEe3Fa4177edE3F63FA',
    tokenAddress: '0x140b890bf8e2fe3e26fcd516c75728fb20b31c4f',
    usdAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    usdDecimals: 6,
    updateInterval: 30000,
    rate: 10000000n,
    nativeTxGas: 15010499,
    nativeTokenSymbol: 'ETH',
    stableCoinSymbol: 'USDT',
    crowdsaleAddresses: ['0xed1B150F589c5E3d4b7d5CEe3Fa4177edE3F63FA']
  },
  saleGoal: 100000000000n,
  tokenDecimals: 4,
  minSaleProgress: 12,
  // These will be used to buy tokens if ref param is present in URL query.
  // All addresses here should also be listed in [network].crowdsaleAddresses array
  discounts: [
    {
      ref: 'ref_tester',
      contract: '0xed1B150F589c5E3d4b7d5CEe3Fa4177edE3F63FA',
      network: 'bsc',
      rate: 9000000n
    }
  ]
}
