import React from 'react'
import styled from 'styled-components'

export interface AddressProps {
  value: string | null
  style?: React.CSSProperties
}

const shortAddress = (addr: string, n: number) => {
  return `${addr.substring(0, n + 2)}...${addr.substring(
    addr.length - n,
    addr.length
  )}`
}

const Styled = styled.div`
  display: inline-block;
  border-radius: 32px;
  border: solid var(--secondary) 1px;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #fff;
  background-color: var(--secondary);
  font-weight: 700;
  padding: 4px 12px;
  width: max-content;
`

const Address: React.FC<AddressProps> = ({ value, style }) => {
  return (
    <Styled style={style}>{value ? shortAddress(value, 4) : 'Unknown'}</Styled>
  )
}

export default Address
