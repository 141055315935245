import React from 'react'

import { StatsBox, StatsContainer, StatsColumn } from './BuyTokenStats.styled'
import ProgressBar from './ProgressBar/ProgressBar'
import BuyTokenStat from './BuyTokenStat/BuyTokenStat'

import { useEth } from '../../../../Logic/Eth/EthProvider'

const BuyTokenStats = () => {
  const eth = useEth()

  const getTokenPrice = () => {
    const rate = eth.services.utils.getRate()
    const price = (Number(rate) / 10 ** 8).toFixed(2)
    return `$ ${price}`
  }

  const calculateInvestment = () =>
    eth.metamask.status === 'connected'
      ? (() => {
          try {
            const locked = eth.update.userLockedBalance ?? 0n
            return (Number(locked) / 10 ** 4).toFixed(4)
          } catch (e) {
            console.error(e)
            return '0'
          }
        })()
      : '–'

  return (
    <StatsBox>
      <StatsContainer>
        <StatsColumn width={58}>
          <BuyTokenStat label="Sale starts [UTC]:" content="03.11.2021" />
          <BuyTokenStat
            label="Tokens available in this phase:"
            content="10 000 000 BUFFS"
          />
          {/* <BuyTokenStat label="Min. investment limit:" content="$ 200" /> */}
          <BuyTokenStat
            label="Your investment:"
            content={calculateInvestment()}
          />
        </StatsColumn>
        <StatsColumn width={42}>
          <BuyTokenStat label="Sale ends [UTC]:" content="29.11.2021" />
          <BuyTokenStat label="Platform raise:" content="$ 1 000 000" />
          <BuyTokenStat label="Price per token:" content={getTokenPrice()} />
        </StatsColumn>
      </StatsContainer>
      <ProgressBar />
    </StatsBox>
  )
}

export default BuyTokenStats
