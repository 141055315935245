// import Demo from '../Views/Demo/Demo'
// import LandingPage from '../Views/LandingPage/LandingPage'
// import PreSale from '../Views/PreSale/PreSale'
// import Dev from '../Views/Dev/Dev'
// import App from '../Views/App/App'
import BuyTokenMainView from '../Views/BuyToken/BuyTokenMainView'
import NotFound from '../Views/NotFound/NotFound'

export interface Route {
  path: string
  component: React.FC
}

const routes: Route[] = [
  {
    path: '/',
    component: BuyTokenMainView
  },
  {
    path: '*',
    component: NotFound
  }
  // {
  //   path: '/',
  //   component: LandingPage
  // },
  // {
  //   path: '/presale',
  //   component: PreSale
  // },
  // {
  //   path: '/dev',
  //   component: Dev
  // },
  // {
  //   path: '/claim-token',
  //   component: BuyTokenMainView
  // },
  // {
  //   path: '/app',
  //   component: App
  // },
  // {
  //   path: '/Whitepaper',
  //   component: Whitepaper
  // },
  // {
  //   path: '*',
  //   component: NotFound
  // }
]

export default routes
