import styled from 'styled-components'
import React from 'react'
import ViewControllerOption from './ViewControllerOption'

export type ViewType = 'buy' | 'home'

export interface ViewControllerProps {
  value: ViewType
  setValue: (value: ViewType) => void
  style?: React.CSSProperties
}

const Styled = styled.div`
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
    0px 4px 8px rgba(96, 97, 112, 0.16);
  display: inline-flex;
  align-items: center;
  padding: 6px;
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1360px) {
    position: relative;
    margin-left: 0px;
    transform: none;
  }
`

const ViewController: React.FC<ViewControllerProps> = (props) => {
  return (
    <Styled style={props.style}>
      {/* <ViewControllerOption
        text="Buy Tokens"
        onClick={() => props.setValue('buy')}
        active={props.value === 'buy'}
      /> */}
      <ViewControllerOption
        text="Claim Tokens"
        onClick={() => {
          props.setValue('home')
        }}
        active={props.value === 'home'}
      />
    </Styled>
  )
}

export default ViewController
