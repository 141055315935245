import { TranslationProvider } from './context/translationContext'
import { useAOS } from './Logic/Aos'
import { EthereumProvider } from './Logic/Eth/EthProvider'
import Routing from './Routes/Routing'
import './Translation/Translation'

const App: React.FC = () => {
  useAOS()

  return (
    <TranslationProvider>
      <EthereumProvider initialConfig="bsc">
        <Routing />
      </EthereumProvider>
    </TranslationProvider>
  )
}

export default App
