import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { useCounter } from '../../../../../Logic/counter'
import { useTranslation } from 'react-i18next'
import Space from '../../../../../Components/Space'
import config from '../../../../../config'

const Title = styled.div`
  color: #1d1c39;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: center;
`

const Digit = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(214, 215, 223, 0.25);
  font-size: 18px;
  font-weight: 700;
  line-height: 2;
  margin: 4px;
  padding: 3px;
  color: #1d1c39;
  border-radius: 4px;
  width: 80px;
  filter: drop-shadow(0px 5.38462px 11.5385px rgba(29, 28, 57, 0.3));

  @media (max-width: 991px) {
    font-size: 14px;
    width: 64px;
    filter: drop-shadow(0px 2.38462px 2.5385px rgba(29, 28, 57, 0.3));
  }

  @media (max-width: 670px) {
    font-size: 14px;
    width: 44px;
  }
`

const Caption = styled.div`
  color: #8e90a6;
  font-size: 10px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  /* margin-top: 10px; */

  @media (max-width: 576px) {
    /* margin-top: 10px; */
  }
`

const Section = styled.section`
  background: #ffffff;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`

const DigitsGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const CounterTimeLeft: React.FC = () => {
  const { t } = useTranslation()
  const digitGroups = useCounter(config.roundOneLeft)

  const captions = [
    t('presale.counter.days'),
    t('presale.counter.hours'),
    t('presale.counter.minutes'),
    t('presale.counter.seconds')
  ]

  return (
    <Section>
      <Container style={{ display: 'block' }} data-aos="fade-up">
        <Row style={{ padding: '0 10px' }}>
          <Title>{t('app.connectWallet.claimTimeLeft')}</Title>
          <Space height={10} />
          {digitGroups.map((digitGroup, index) => (
            <Col xl={3} lg={3} md={3} sm={3} xs={6} key={index} style={{}}>
              <DigitsGroup>
                {digitGroup.map((digit, index) => (
                  <Digit key={index}>{digit}</Digit>
                ))}
              </DigitsGroup>
              <Caption>{captions[index]}</Caption>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default CounterTimeLeft
