import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Info } from './icons/info.svg'

export interface Information {
  text: string
  link?: string
  chainlink?: string
  onClick?: () => void
}

const Styled = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const Span = styled.span`
  max-width: 387px;
  width: 100%;
  font-size: 15px;
`

const ButtonAsLink = styled.button`
  color: #0d6efd;
  text-decoration: underline;
  touch-action: manipulation;
  background-color: initial;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  border: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    outline: 0;
  }

  &:hover {
    color: #0a58ca;
  }

  &:active {
    color: #096dd9;
  }
`

const InformationBar: React.FC<Information> = (props) => {
  return (
    <React.Fragment>
      <Styled>
        <span>
          <Info style={{ marginRight: '20px' }} />
        </span>
        <Span>
          {props.text}
          {props.link && (
            <a href={props.link} target="_blank" rel="noreferrer">
              {' '}
              {props.chainlink}
            </a>
          )}
          {props.onClick && (
            <ButtonAsLink onClick={props.onClick}>
              {' '}
              {props.chainlink}
            </ButtonAsLink>
          )}
        </Span>
      </Styled>
    </React.Fragment>
  )
}

export default InformationBar
