import React from 'react'
import styled from 'styled-components'
import Space from '../../../Components/Space'
import { useEth } from '../../../Logic/Eth/EthProvider'
import { useEthTransaction } from '../../../Logic/Eth/EthTransaction'
import TransactionModal from './TransactionModal/TransactionModal'

export interface TransactionProcessProps {
  inputValue: bigint
  inputPrecision: bigint
  inputToken: string
  handleGrantAccessRejected?: () => void
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: -5px;
  border: 1px solid #e3e4eb;
  border-top: none;
  box-shadow: 0px 38.5185px 51.9185px rgba(0, 0, 0, 0.0425185),
    0px 8.14815px 13.2815px rgba(0, 0, 0, 0.0274815);

  & > span {
    color: #616d6d;
    font-size: 12;
  }

  & .arrow {
    color: #b0b6b6;
    transform: scale(1, 2);
  }
`

const StepNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid white 2px;
  margin-right: 10px;
`

const Button = styled.div<{ disabled?: boolean }>`
  background-color: ${(p) => (p.disabled ? '#B0B6B6' : 'var(--primary)')};
  border-radius: 24px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  color: white;
  display: flex;
  padding: 8px;
  align-items: center;
  font-size: 17px;
  width: max-content;
  margin: 2px;
  padding-right: 18px;
`

const TransactionProcess: React.FC<TransactionProcessProps> = ({
  inputValue,
  inputPrecision,
  inputToken,
  handleGrantAccessRejected
}) => {
  const [granted, setGranted] = React.useState(false)
  const [bought, setBought] = React.useState(false)
  const increaseAllowanceTx = useEthTransaction()
  const buyTx = useEthTransaction()
  const { services } = useEth()

  React.useEffect(() => {
    switch (increaseAllowanceTx.status) {
      case 'success':
        setGranted(true)
        break
      case 'rejected':
        if (handleGrantAccessRejected) break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [increaseAllowanceTx.status])

  React.useEffect(() => {
    if (buyTx.status === 'success') {
      setBought(true)
    }
  }, [buyTx.status])

  return (
    <Styled>
      <Space height={15} />
      <span>Please follow these steps:</span>
      <Space height={15} />
      <Button
        onClick={() =>
          increaseAllowanceTx.init(
            inputValue,
            'increaseAllowance',
            services.utils.getCrowdsaleAddress()
          )
        }
        disabled={granted}
      >
        <StepNumber>1</StepNumber>
        <span>{granted ? 'ACCESS GRANTED' : 'GRANT ACCESS'}</span>
      </Button>
      <Space height={15} />
      <span className="arrow">&#8595;</span>
      <Space height={15} />
      <Button
        onClick={() =>
          buyTx.init(
            inputValue,
            'buyForStablecoin',
            services.utils.getCrowdsaleAddress()
          )
        }
        disabled={!granted || bought}
      >
        <StepNumber>2</StepNumber>
        <span>{bought ? 'SUCCESS!' : 'SWAP'}</span>
      </Button>
      <Space height={15} />
      <TransactionModal
        tx={increaseAllowanceTx}
        type="increaseAllowance"
        inputValue={(Number(inputValue) / 10 ** Number(inputPrecision)).toFixed(
          2
        )}
        inputSymbol={inputToken}
        onDismiss={() => {
          if (
            increaseAllowanceTx.status === 'rejected' &&
            !!handleGrantAccessRejected
          )
            handleGrantAccessRejected()
          increaseAllowanceTx.clear()
        }}
      />
      <TransactionModal
        tx={buyTx}
        type="buy"
        customMessage="Swap selected amount"
      />
    </Styled>
  )
}

export default TransactionProcess
