import styled from 'styled-components'
import Text from '../../../../../Components/Text'

export const Container = styled.div`
  width: 100%;
`

export const StyledLabel = styled(Text)`
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 0;
`

export const StyledContent = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #000;
`
