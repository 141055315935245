import { EthService } from './EthService'
import { Contract } from 'web3-eth-contract'
import { EthUpdate } from '../EthTypes'

export type BatchCallback = (err: Error, value: any) => void

export type BatchRequest = { name: string; func: (cb: BatchCallback) => any }

export type BatchResult = { name: string; value: any }

export interface CrowdsaleData {
  balance: bigint | null
  maxBalance: bigint | null
  maxTokensToUnlock: bigint | null
  locked: bigint | null
  address?: string
}

export interface CommonData {
  userNativeBalance: bigint | null
  userUSDBalance: bigint | null
  gasPrice: bigint | null
  ethPrice: bigint | null
  userBUFFSBalance: bigint | null
}

export class EthReadService extends EthService {
  async fetchData() {
    const crowdsalesData: CrowdsaleData[] = await Promise.all(
      this.crowdsaleContracts.map(async (contract) => {
        const entries = await this.batchRequest(
          this.createCrowdsaleReadRequests(contract)
        )

        return {
          ...this.transformResults(entries),
          address: contract.options.address
        }
      })
    )

    const commonEntries = await this.batchRequest(this.createCommonRequests())
    const common: CommonData = this.transformResults(commonEntries)

    return this.transformToEthUpdate(common, crowdsalesData)
  }

  private transformToEthUpdate(
    common: CommonData,
    crowdsales: CrowdsaleData[]
  ): EthUpdate {
    const reduced = this.reduceCrowdsalesData(crowdsales)

    return {
      ...common,
      userLockedBalance: reduced.balance,
      userMaxBalance: reduced.maxBalance,
      totalLocked: reduced.locked,
      userAvailableBUFFS: reduced.maxTokensToUnlock,
      contracts: crowdsales
    }
  }

  private reduceCrowdsalesData(arr: CrowdsaleData[]): CrowdsaleData {
    const joinProp = (a: any, b: any, prop: string): bigint => {
      return (
        (typeof a[prop] === 'bigint' ? a[prop] : 0n) +
        (typeof b[prop] === 'bigint' ? b[prop] : 0n)
      )
    }

    const initialValues: CrowdsaleData = {
      balance: 0n,
      maxBalance: 0n,
      maxTokensToUnlock: 0n,
      locked: 0n
    }

    return arr.reduce(
      (prev, curr) => ({
        balance: joinProp(prev, curr, 'balance'),
        maxBalance: joinProp(prev, curr, 'maxBalance'),
        maxTokensToUnlock: joinProp(prev, curr, 'maxTokensToUnlock'),
        locked: joinProp(prev, curr, 'locked')
      }),
      initialValues
    )
  }

  private transformResults(results: BatchResult[]) {
    // Currently all values are integers

    const entries = results.map(({ name, value }) => {
      if (value === null) return { name, value }

      try {
        return { name, value: BigInt(value) }
      } catch (e) {
        console.error(e)
        return { name, value: null }
      }
    })

    return entries.reduce(
      (acc, curr) => ({ ...acc, [curr.name]: curr.value }),
      {} as any
    )
  }

  private createCrowdsaleReadRequests(crowdsale: Contract): BatchRequest[] {
    const withUserAddress: BatchRequest[] = [
      {
        name: 'balance',
        func: this.createContractRequest(crowdsale, 'balance', [
          this.userAddress
        ])
      },
      {
        name: 'maxBalance',
        func: this.createContractRequest(crowdsale, 'maxBalance', [
          this.userAddress
        ])
      },
      {
        name: 'maxTokensToUnlock',
        func: this.createContractRequest(crowdsale, 'maxTokensToUnlock', [
          this.userAddress
        ])
      }
    ]

    const withoutUserAddress: BatchRequest[] = [
      {
        name: 'locked',
        func: this.createContractRequest(crowdsale, 'locked')
      }
    ]

    if (!this.userAddress) return withoutUserAddress

    return [...withUserAddress, ...withoutUserAddress]
  }

  private createCommonRequests(): BatchRequest[] {
    const withUserAddress: BatchRequest[] = [
      {
        name: 'userNativeBalance',
        func: this.createBalanceRequest(this.userAddress!)
      },
      {
        name: 'userBUFFSBalance',
        func: this.createContractRequest(this.tokenContract, 'balanceOf', [
          this.userAddress
        ])
      },
      {
        name: 'userUSDBalance',
        func: this.createContractRequest(this.usdContract, 'balanceOf', [
          this.userAddress
        ])
      }
    ]

    const withoutUserAddress: BatchRequest[] = [
      {
        name: 'gasPrice',
        func: this.createGasPriceRequest()
      },
      {
        name: 'ethPrice',
        func: this.createContractRequest(
          this.crowdsaleMainContract,
          'currentEthPrice'
        )
      }
    ]

    if (!this.userAddress) return withoutUserAddress

    return [...withUserAddress, ...withoutUserAddress]
  }

  private createBalanceRequest(address: string) {
    return (cb: BatchCallback) =>
      (this.web3 as any).eth.getBalance.request(address, 'latest', cb)
  }

  private createGasPriceRequest() {
    return (cb: BatchCallback) => (this.web3 as any).eth.getGasPrice.request(cb)
  }

  private createContractRequest(
    contract: Contract,
    method: string,
    args: any[] = []
  ) {
    return (cb: BatchCallback) =>
      contract.methods[method](...args).call.request(cb)
  }

  private batchRequest(requests: BatchRequest[]) {
    const batch = new this.web3.eth.BatchRequest()

    const results = requests.map((req) => ({
      name: req.name,
      fulfilled: false,
      value: null as any
    }))

    return new Promise<any[]>((resolve) => {
      for (const index in requests) {
        const i = Number(index)

        batch.add(
          requests[i].func((err, value) => {
            if (err) {
              console.error(err)
              results[i].value = null
            } else {
              results[i].value = value
            }

            results[i].fulfilled = true

            const fulfilled = results.every((obj) => obj.fulfilled)
            if (!fulfilled) return

            resolve(
              results.map((result) => ({
                name: result.name,
                value: result.value
              }))
            )
          })
        )
      }

      batch.execute()
    })
  }
}
