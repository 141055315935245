import styled from 'styled-components'
import CloseButton from './CloseButton'

export interface ModalWrapperProps {
  visible: boolean
  onDismiss: () => void
  allowToDismiss: boolean
}

const Styled = styled.div`
  max-width: 400px;
  min-height: 200px;
  width: calc(100vw - 20px);
  background-color: white;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -80%);
  padding: 10px;
  border-radius: 6px;
  background-color: #fefefe;
  box-shadow: 0px 30.7551px 41.4543px rgba(0, 0, 0, 0.0425185),
    0px 6.50589px 10.6046px rgba(0, 0, 0, 0.0274815);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  z-index: 30;

  & .content {
    margin-top: 10px;
    width: 100%;
  }
`

const ModalWrapper: React.FC<ModalWrapperProps> = (props) => {
  if (!props.visible) return null

  return (
    <Styled>
      <CloseButton disabled={!props.allowToDismiss} onClick={props.onDismiss} />
      <div className="content">{props.children}</div>
    </Styled>
  )
}

export default ModalWrapper
