import { useHistory } from 'react-router'
import styled from 'styled-components'
import ReactGA from 'react-ga'

export interface ButtonProps {
  text: string
  variant: 'primary' | 'secondary'
  arrow: boolean
  onClick?: (e?: any) => any
  link?: string
  style: React.CSSProperties
  disabled?: boolean
  GAEvent?: string
}

const BUTTON_DEFAULT_PROPS: ButtonProps = Object.freeze({
  text: '',
  variant: 'secondary',
  arrow: false,
  style: {}
})

const CustomButton = styled.button<{ variant: 'primary' | 'secondary' }>`
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  border-radius: 1000px;
  color: white;
  box-shadow: 4.82956px 5.06708px 9px rgba(0, 0, 0, 0.15);
  border: 0;
  transition: filter 0.15s linear;
  filter: brightness(1);
  background-color: ${({ variant }) => `var(--${variant})`};

  &:hover {
    filter: ${({ variant }) =>
      `brightness(${variant === 'primary' ? '1.05' : '1.15'})`};
  }

  &:disabled {
    background-color: #aaa;
    cursor: unset;
    filter: none;
  }
`

const Button: React.FC<Partial<ButtonProps>> = (_props) => {
  const props = { ...BUTTON_DEFAULT_PROPS, ..._props }
  const history = useHistory()

  const onClick = () => {
    if (props.GAEvent) {
      ReactGA.event({ category: 'Button', action: props.GAEvent })
    }

    if (props.onClick) {
      props.onClick()
      return
    }

    if (props.link) {
      if (props.link.startsWith("http")) {
        window.open(props.link, "_blank")!.focus()
        return
      }
      history.push(props.link)
    }
  }

  return (
    <CustomButton
      onClick={onClick}
      variant={props.variant}
      style={props.style}
      disabled={props.disabled}
    >
      {props.text}
      {props.arrow && (
        <img
          style={{ marginLeft: 10 }}
          src="/assets/images/common/arrow-right.svg"
          alt="arrow-right"
        />
      )}
    </CustomButton>
  )
}

export default Button
