export interface ActiveInactiveTabProps {
  name: string
  selected: string
  active: React.ReactNode
  inactive: React.ReactNode
}

const ActiveInactiveTab: React.FC<ActiveInactiveTabProps> = (props) => {
  return (
    <span>
      {props.selected === props.name && (
        <>
          {props.name}
          {props.active}
        </>
      )}
      {props.selected !== props.name && (
        <>
          <span
            style={{
              color: 'rgba(29, 28, 57, 0.4)'
            }}
          >
            {props.name}
          </span>
          {props.inactive}
        </>
      )}
    </span>
  )
}

export default ActiveInactiveTab
