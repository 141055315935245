import styled, { keyframes } from 'styled-components'

export interface SpinnerProps {
  size: number
  color: string
  style?: React.CSSProperties
  width?: number
}

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Container = styled.div<SpinnerProps>`
  display: inline-block;
  position: relative;
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};
  filter: blur(0.6px);
`

const Inner = styled.div<SpinnerProps>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80%;
  height: 80%;
  margin: 10%;
  border: ${(p) => `${p.size / (p.width ?? 10)}px`} solid ${(p) => p.color};
  border-radius: 50%;
  animation: ${rotate} 1.2s linear infinite;
  border-color: ${(p) => p.color} transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`

const Spinner: React.FC<SpinnerProps> = (props) => {
  return (
    <Container {...props} style={props.style}>
      <Inner {...props} />
      <Inner {...props} />
      <Inner {...props} />
      <Inner {...props} />
    </Container>
  )
}

export default Spinner
