import React from 'react'
import ReactGA from 'react-ga'

export const matchPathname = (path: string) => {
  switch (path) {
    case '/':
      return '/home'
    case '*':
      return '/not-found'
    default:
      return path
  }
}

export const createGAWrapper = (
  Component: React.FC,
  path: string
): React.FC => {
  return () => {
    React.useEffect(() => {
      ReactGA.pageview(matchPathname(path))
    }, [])

    return <Component />
  }
}
