import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createGAWrapper } from './GAWrapper'
import routes from './routes'

const Routing: React.FC = () => {
  return (
    <Router>
      <Switch>
        {routes.map((params) => (
          <Route
            key={params.path}
            exact
            path={params.path}
            component={createGAWrapper(params.component, params.path)}
          />
        ))}
      </Switch>
    </Router>
  )
}

export default Routing
