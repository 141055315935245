import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from 'react-service-worker'
import App from './App'
import ReactGA from 'react-ga'
import config from './config'
import { EthUtilsService } from './Logic/Eth/services/EthUtilsService'

// Import CSS
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import 'aos/dist/aos.css'

registerServiceWorker()

ReactGA.initialize(config.googleAnalytics)

EthUtilsService.copyRefToSession()

const app = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

ReactDOM.render(app, document.getElementById('root'))
