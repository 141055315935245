const config = Object.freeze({
  emails: ['OFFICE@BUFFSWAP.IO', 'SUPPORT@BUFFSWAP.IO', 'TRADING@BUFFSWAP.IO'],
  socialLinks: {
    telegram: 'https://t.me/BUFFSChat',
    twitter: 'https://twitter.com/buff_swap',
    youtube: 'https://www.youtube.com/channel/UC33_vehzmHQWPvO6KLMwhnw',
    medium: 'https://buffswap.medium.com'
  },
  presaleTimestamp: 1635955200000, //  (GMT): Wednesday, November 3, 2021 4:00:00 PM,
  presaleEnd: 1638212400000,
  roundOneLeft: 1639958400000,
  exchange: {
    ethAddress: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    bscAddress: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
  },
  telegramLinks: [
    { tag: '#BUFFSNews', url: 'https://t.me/BUFFSNews' },
    { tag: '#BUFFSChat', url: 'https://t.me/BUFFSChat' }
  ],
  googleAnalytics: 'UA-208885152-1',
  ercAddress: '0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f',
  linkErc:
    'https://etherscan.io/token/0x140b890bf8e2fe3e26fcd516c75728fb20b31c4f',
  bscAddress: '0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f',
  linkBsc:
    'https://bscscan.com/token/0x140b890bf8e2fe3e26fcd516c75728fb20b31c4f',
  buffAddress: '0x140b890bf8e2fe3e26fcd516c75728fb20b31c4f',
  buffSymbol: 'BUFFS',
  buffDecimals: '4',
  CROWDSALE: '0xd829486b391FDf8b5e7967570689d4D85a62Da3E'
})

export default config
