import React from 'react'
import styled from 'styled-components'
import { useEth } from '../../../../Logic/Eth/EthProvider'

export interface BalanceInfoProps {
  style?: React.CSSProperties
}

const Styled = styled.div`
  display: inline-block;
  border-radius: 32px;
  border: solid var(--secondary) 1px;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #8e90a6;
  padding: 4px 12px;
  width: max-content;
`

const strValue = (num: bigint | null) => {
  if (!num) return '0'

  return (Number(num) / 10 ** 18).toFixed(4)
}

const BalanceInfo: React.FC<BalanceInfoProps> = ({ style }) => {
  const { update, config } = useEth()

  return (
    <Styled style={style}>
      {strValue(update.userNativeBalance)} {config.nativeTokenSymbol}
    </Styled>
  )
}

export default BalanceInfo
