import Web3 from 'web3'
import { Contract } from 'web3-eth-contract'
import { EthNetworkConfig, EthProvider } from '../EthTypes'
import CrowdsaleAbi from '../abi/CrowdsaleAbi.json'
import TokenAbi from '../abi/TokenAbi.json'

export abstract class EthService {
  protected provider: () => EthProvider
  protected config: EthNetworkConfig
  protected web3: Web3

  protected crowdsaleMainContract: Contract
  protected crowdsaleContracts: Contract[]
  protected tokenContract: Contract
  protected usdContract: Contract

  constructor(config: EthNetworkConfig, provider?: () => EthProvider) {
    const defaultProvider = () => (globalThis as any).ethereum
    this.provider = provider ?? defaultProvider

    this.config = config
    this.web3 = new Web3(config.rpcUrl)

    this.initContracts()
  }

  get eth() {
    return this.provider()
  }

  get userAddress() {
    return this.eth?.selectedAddress ?? null
  }

  private initContracts() {
    this.crowdsaleMainContract = this.initContract(
      CrowdsaleAbi,
      this.config.crowdsaleMainAddress
    )

    this.crowdsaleContracts = this.config.crowdsaleAddresses.map((address) =>
      this.initContract(CrowdsaleAbi, address)
    )

    this.tokenContract = this.initContract(TokenAbi, this.config.tokenAddress)
    this.usdContract = this.initContract(TokenAbi, this.config.usdAddress)
  }

  protected initContract(abi: any, address: string) {
    return new this.web3.eth.Contract(abi, address)
  }
}
