export const claimTimestamp5: number[] = [
  1639094400, 1639958400, 1640822400, 1641772800, 1642636800, 1643500800
]

export const claimTimestamp4: number[] = [
  1644451200, 1645315200, 1646092800, 1646870400, 1647734400, 1648598400,
  1649548800, 1650412800, 1651276800, 1652140800, 1653004800, 1653868800,
  1654819200, 1655683200, 1656547200, 1657411200
]

export const claimTimestamp6: number[] = [1658275200]

export const claimPeriods: { percents: number; timestamps: number[] }[] = [
  { percents: 5, timestamps: claimTimestamp5 },
  { percents: 4, timestamps: claimTimestamp4 },
  { percents: 6, timestamps: claimTimestamp6 }
]

export const firstDate = 1639094400
export const lastDate = 1658275200

export type ClaimPeriodType = { percents: number; timestamps: number[] }
