import React from 'react'
import styled from 'styled-components'
import { useEth } from '../../../Logic/Eth/EthProvider'
import WalletWarning from './WalletWarning'
import Button from '../../../Components/Button'
// import WalletConnected from '../WalletConnected'
import { BuyTokenInterface } from '../utils/enums'
import { isMobile } from 'react-device-detect'
import LinkButton from '../../../Components/LinkButton'

export interface WalletDetailsProps {
  localState: BuyTokenInterface
}

const Styled = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  height: 265px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: none;
  box-shadow: 0px 36.0747px 48.6246px rgba(0, 0, 0, 0.0425185),
    0px 7.6312px 12.4389px rgba(0, 0, 0, 0.0274815);

  & .desc {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #616d6d;
    width: 100%;
    text-align: center;
    margin: 8px 0;
  }

  & button {
    width: 100%;
    padding: 10px 10px;
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
  }
`

const WalletDetails: React.FC<WalletDetailsProps> = ({ localState }) => {
  const eth = useEth()
  return (
    <Styled
      style={{
        display:
          eth.metamask.status !== 'connected' || !!eth.error ? 'flex' : 'none',
        backdropFilter:
          eth.metamask.status !== 'connected' || !!eth.error
            ? 'blur(4px)'
            : 'none',
        backgroundColor:
          eth.metamask.status !== 'connected' || !!eth.error
            ? 'rgba(255, 255, 255, 0.9)'
            : 'none',
        zIndex: eth.metamask.status !== 'connected' || !!eth.error ? 5 : 0,
        padding:
          eth.metamask.status === 'connected' ||
          eth.metamask.status === 'connecting'
            ? 0
            : 15
      }}
    >
      {eth.metamask.status === 'unavailable' && (
        <React.Fragment>
          {!isMobile && (
            <>
              <WalletWarning text="not installed" />
              <span className="desc">Please install Metamask extension</span>
            </>
          )}
          {isMobile && (
            <LinkButton
              style={{ marginTop: 40, width: 400 }}
              text="OPEN IN METAMASK"
              variant="secondary"
              // onClick={eth.metamask.connect}

              link="https://metamask.app.link/dapp/buffswap.io/buy-token"
            />
          )}
        </React.Fragment>
      )}
      {eth.metamask.status === 'notConnected' && (
        <React.Fragment>
          <WalletWarning text="not connected" />
          {/* <span className="desc">Please connect your wallet</span> */}
          <Button
            style={{ marginTop: 40, marginBottom: 12 }}
            text="CONNECT WALLET"
            variant="secondary"
            onClick={eth.metamask.connect}
          />
        </React.Fragment>
      )}
      {eth.error === 'invalid_chain_id' && (
        <React.Fragment>
          <WalletWarning text="invalid network" />
          <span className="desc">Change network in Metamask</span>
        </React.Fragment>
      )}
      {/* {eth.metamask.status === 'connected' && !eth.error && (
        <React.Fragment>
          <WalletConnected text={''} />
        </React.Fragment>
      )} */}
    </Styled>
  )
}

export default WalletDetails
