import { Col, Container, Row } from 'react-bootstrap'
// import BettingAd from '../components/BettingAd'
import styled from 'styled-components'
import BettingTrait from '../components/BettingTrait'
import Heading from '../../../Components/Heading'
import Text from '../../../Components/Text'
import { useTranslation } from 'react-i18next'
import Space from '../../../Components/Space'

const Section = styled.section`
  background-color: white;
  padding-bottom: 20px;
`

export const Hr = styled.hr`
  margin: 50px 0;
  margin-top: 0px;
  background-color: #bbb;
`

const PadContainer = styled.div`
  height: 0;
  transform: translate(-40px, -51px);
  width: 34%;

  @media (max-width: 991px) {
    transform: translate(0, -51px);
    margin: auto;
    width: 50%;
    height: auto;
  }
`

const Pad: React.FC = () => (
  <PadContainer>
    <img
      src="/assets/images/landing/betting-pad.svg"
      alt="betting-pad"
      style={{ width: '100%' }}
    />
  </PadContainer>
)

const CustomCol: React.FC<{ wide?: boolean }> = (props) => (
  <Col
    data-aos="fade-up"
    style={{ marginBottom: 30 }}
    xs={12}
    sm={12}
    md={props.wide ? 6 : 12}
    lg={4}
    xl={4}
    xxl={4}
  >
    {props.children}
  </Col>
)

const BettingPlatform: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Section id="About">
      {/* <Space height={20} /> */}
      <Container>
        <Hr />
        <Pad />
        <Row>
          <CustomCol></CustomCol>
          <CustomCol>
            <Heading style={{ fontSize: 36 }}>
              {t('landing.betting.heading')}
            </Heading>
            <Space height={35} />
          </CustomCol>
          <CustomCol>
            <Text>{t('landing.betting.text')}</Text>
            <Space height={0} />
          </CustomCol>
        </Row>
        <Row>
          <CustomCol></CustomCol>
          <CustomCol wide>
            <BettingTrait
              image="/assets/images/landing/betting-hand.svg"
              text={t('landing.betting.card1.text')}
              animationDelay="1s"
            />
          </CustomCol>
          <CustomCol wide>
            <BettingTrait
              image="/assets/images/landing/betting-cash.svg"
              text={t('landing.betting.card2.text')}
              animationDelay="2s"
            />
          </CustomCol>
        </Row>
        <Hr />
        <Row>
          <CustomCol wide>
            <BettingTrait
              image="/assets/images/landing/betting-diamond.svg"
              heading={t('landing.betting.card3.title')}
              text={t('landing.betting.card3.text')}
              animationDelay="0s"
            />
          </CustomCol>
          <CustomCol wide>
            <BettingTrait
              image="/assets/images/landing/betting-drink.svg"
              heading={t('landing.betting.card4.title')}
              text={t('landing.betting.card4.text')}
              animationDelay="1s"
            />
          </CustomCol>
          <CustomCol wide>
            <BettingTrait
              image="/assets/images/landing/betting-circles.svg"
              heading={t('landing.betting.card5.title')}
              text={t('landing.betting.card5.text')}
              animationDelay="2s"
            />
          </CustomCol>
        </Row>
      </Container>
    </Section>
  )
}

export default BettingPlatform
