import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import {
  createPaddings,
  fixupProgressForPaddings,
  timeToProgress
} from '../../../../../Logic/ClaimVertProgressUtil'
import { claimPeriods, firstDate, lastDate } from './Dates'
import { ProgressBarDateFill } from './ProgressBar.styled'

const ProgressBarHeight = 457

const ProgressBarWrapper = styled.div`
  position: absolute;
  width: 5px;
  height: ${ProgressBarHeight}px;
  top: 20px;
  right: 45px;
`

export const ProgressBarDate = () => {
  // const actualDate = claimPeriods[1].timestamps[0] //- 3600 * 24 * 5
  const dateNow = new Date()
  const actualDate = moment(dateNow).unix()

  const calculatePeriods = () => {
    const percents = timeToProgress(firstDate, lastDate, actualDate)
    const fixedPercents = fixupProgressForPaddings(
      percents,
      ProgressBarHeight,
      6,
      actualDate,
      createPaddings(claimPeriods)
    )
    return fixedPercents
  }

  return (
    <ProgressBarWrapper>
      <ProgressBarDateFill percentage={calculatePeriods()} />
    </ProgressBarWrapper>
  )
}
