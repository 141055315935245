import styled from 'styled-components'
import React from 'react'

export interface CopyButtonProps {
  text: string
  style?: React.CSSProperties
}

const Styled = styled.div<{ copied: boolean }>`
  font-size: 16px;
  border: solid black 1px;
  transition: all 0.2s linear;
  border-color: ${(p) => (p.copied ? '#ccc' : 'var(--primary)')};
  border-radius: 26px;
  padding: 6px 24px;
  color: ${(p) => (p.copied ? '#ccc' : 'var(--primary)')};
  cursor: pointer;
  display: inline-block;
  width: 100px;
  text-align: center;

  &:hover {
    color: ${(p) => (p.copied ? '#ccc' : 'var(--secondary)')};
    border-color: ${(p) => (p.copied ? '#ccc' : 'var(--secondary)')};
  }
`

const CopyButton: React.FC<CopyButtonProps> = (props) => {
  const [copied, setCopied] = React.useState(false)

  React.useEffect(() => {
    if (!copied) return

    const handle = setTimeout(() => {
      setCopied(false)
    }, 1000)

    return () => clearTimeout(handle)
  }, [copied])

  const onClick = () => {
    navigator.clipboard.writeText(props.text)
    setCopied(true)
  }

  return (
    <Styled onClick={onClick} style={props.style} copied={copied}>
      <span>{copied ? 'copied' : 'copy'}</span>
    </Styled>
  )
}

export default CopyButton
